.export-country-categories {
  margin-top: 2%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.add-categories-and-products {
  width: fit-content;
}
.categoryImage {
  border-radius: 100%;
}
.add-containers {
  display: flex;
  justify-content: center;
  width: fit-content;
  margin-top: 20px;
}
.check {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  align-items: center;
}
