.final-invoice-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 32px;
  gap: 10px;
  height: 100%;
  width: 100%;
  background: var(--color-white);
  border: 1px solid var(--color-border);
  border-radius: 24px;
  box-shadow: -16px 13px 4px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -9px 9px 0px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -16px 13px 4px 0px rgba(0, 0, 0, 0.75);
  cursor: pointer;
}
.final-invoice-card:hover {
  box-shadow: -16px 13px 4px 0px rgba(214, 211, 18, 0.75);
  -webkit-box-shadow: -9px 9px 0px 0px var(--color-primary);
  -moz-box-shadow: -16px 13px 4px 0px rgba(188, 190, 34, 0.75);
  transition: var(--transition);
}
.shipmentInvoiceheader {
  display: flex;
}
.shipmentInvoiceheader > img {
  background: black;
  height: 80px;
  border-radius: 24px;
  margin: 0 20px 0 0;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}
.shipmentInvoiceheaderInfo > div > span {
  font-size: 32px;
}
.shipmentInvoiceInfo {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: flex-start;
  justify-content: space-around;
}
.shipmentInvoiceInfo div h5 {
  font-size: 22px;
  margin: 14px 0 24px 0;
}
.shipment-container-info {
  font-family: "Nunito Sans";
}
.shipment-container-info table tbody {
  color: gray;
}
.shipment-container-info thead tr:first-child {
  padding-bottom: 1rem !important;
  border-bottom: 1px solid var(--color-border);
  height: 3.4rem;
}

.final-invoice-btn-group {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  justify-content: space-around;
}
.final-invoice-btn-group .invoice-btn {
  width: 90%;
  margin: 0 20px;
}
.shipmentInvoiceInfo-products {
  height: 15rem;
  overflow: hidden;
  overflow-y: auto;
  font-family: "Nunito Sans";
}
.shipmentInvoiceInfo-products table thead tr:first-child {
  border-bottom: 1px solid var(--color-border);
  height: 3.4rem;
}
.products-table-body {
  font-size: 16px;
  color: gray;
}

#invoice-card-spacer {
  margin-top: 16px;
}
#invoice-card-hr {
  display: none;
}
#invoice-card-table-spacer {
  margin-bottom: 8px;
}
/*===================MEDIA QUERYIES (MEDIUM DEVICES)======================*/

@media screen and (max-width: 1024px) {
  .final-invoice-card {
    width: 100%;
  }
  .shipmentInvoiceheaderInfo h3 {
    margin-top: 20px;
    font-size: 24px;
  }
  #invoice-card-table-spacer {
    margin-bottom: 0px;
  }
}

/*===================MEDIA QUERYIES (SMALL DEVICES)======================*/

@media screen and (max-width: 600px) {
  .final-invoice-card {
    padding: 12px;
  }
  .shipmentInvoiceheader {
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
  }
  .shipmentInvoiceInfo {
    grid-template-columns: repeat(1, 1fr);
  }
  .final-invoice-btn-group {
    grid-template-columns: repeat(1, 1fr);
    gap: 16px;
    margin-top: 8px;
    margin-bottom: 24px;
  }
  .final-invoice-btn-group .invoice-btn {
    margin: 0;
    width: auto;
  }
  .shipmentInvoiceheader > img {
    width: 56px;
    height: 54px;
    margin: 0;
    border-radius: 8px;
  }
  .shipmentInvoiceheaderInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: start;
    gap: 4px;
  }
  .shipmentInvoiceheaderInfo div {
    margin-top: 13px;
  }
  .shipmentInvoiceheaderInfo div > span:first-child {
    display: none;
  }
  .shipmentInvoiceheaderInfo div > span:nth-child(2) {
    font-size: 18px;
  }
  .shipmentInvoiceheaderInfo > p {
    padding: 0;
  }
  .shipmentInvoiceheaderInfo > p:nth-child(2) {
    margin: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .shipmentInvoiceheaderInfo > p:nth-child(2) span {
    margin-top: 3px;
    font-size: 15px;
  }
  .shipmentInvoiceheaderInfo img {
    width: 20px;
    height: 20px;
  }
  .responsiveTable tbody tr {
    border: 1px solid #ddd !important;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 12px !important;
    font-family: "Nunito Sans";
  }
  .responsiveTable td .tdBefore {
    font-size: 16px;
    font-weight: 400 !important;
    margin-bottom: 8px !important;
    color: #121212 !important;
  }
  .responsiveTable td.pivoted {
    font-size: 15px;
    font-weight: 400 !important;
  }
  #invoice-card-hr {
    height: 2px;
    width: 190px;
    margin: 0 !important;
    background: #ddd;
    left: 4.2rem;
    position: relative;
  }
  .shipmentInvoiceInfo div h5 {
    font-size: 16px;
    margin-bottom: 16px;
    margin-top: 4px;
  }
  .shipmentInvoiceInfo > div:nth-child(2) > h5 {
    margin: 16px 0;
  }
  #invoice-card-spacer {
    margin-top: 0px;
  }
  #invoice-card-table-spacer {
    margin-bottom: 0px;
  }
}
