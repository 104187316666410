.admin-notifications {
  position: absolute;
  top: 6rem;
  right: 8em;
  display: flex;
  flex-direction: column;
  padding: 1%;
  background: white;
  border-radius: 2px;
  border: 1px solid var(--color-primary);
  height: 80vh;
  width: 35rem;
  overflow: hidden;
  overflow-y: auto;
  z-index: 1;
  gap: 12px;
  display: none;
}

.notification {
  display: flex;
  align-items: center;
  gap: 7px;
  border: 1px solid var(--color-paragraph);
  padding: 3%;
}
.notification .notification-img {
  border-radius: 100%;
  border: 2px solid var(--color-secondary);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.notification .notification-img img {
  width: 100%;
  height: 100%;
}
.notification .notification-info div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.notification .notification-info div span:nth-of-type(1) {
  font-size: 20px;
}
.notification .notification-info div span:nth-of-type(2) {
  color: var(--color-paragraph);
}
.notification .notification-info p {
  color: var(--color-paragraph);
}
/*===================MEDIA QUERYIES (MEDIUM DEVICES)======================*/

@media screen and (max-width: 1024px) {
}

/*===================MEDIA QUERYIES (SMALL DEVICES)======================*/

@media screen and (max-width: 600px) {
  .notifications {
    width: 90%;
    right: 1.5rem;
    top: 10rem;
  }
}
