@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=El+Messiri:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=El+Messiri:wght@400;500;600;700&family=Tajawal:wght@200;300;400;500;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Delius+Unicase:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");
@font-face {
  font-family: "Grillages";
  src: url("/public/Grillages/TTF/Grillages.ttf") format("truetype");
}
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");
:root {
  --color-primary: #ffd13f;
  --dark-one: #343434;
  --dark-two: #26272b;
  --light: #faf7ef;
  --color-primary-variant: #927c4a;
  --color-secondary: #121212;
  --color-secondary-variant: #121212d0;
  --color-paragraph: #657074;
  --color-paragraph-variant: #f8f7f3;
  --color-heading: #fcfcfc;
  --color-heading-variant: #fcfcfcb2;
  --color-white: #fff;
  --color-border: #707070;
  --color-warning: #ff7f52;
  --transition: all 0.5s ease;
 
}
* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  scroll-behavior: smooth !important;
}
*[dir="rtl"] {
  font-family: "El Messiri";
}
html {
  scroll-behavior: smooth !important;
}

::-webkit-scrollbar {
  display: none;
  scroll-behavior: smooth;
}

body {
  font-family: "Delius Unicase", "cursive";
  background: var(--color-white);
  color: var(--color-secondary);
  scroll-behavior: smooth;
  font-weight: 600;
  line-height: 1.6;
}
h1 {
  margin-top: 0;
  font-size: 3.583rem;
}

h2 {
  font-size: 2.986rem;
}

h3 {
  font-size: 2.488rem;
}

h4 {
  font-size: 2.074rem;
}

h5 {
  font-size: 1.728rem;
}
small,
.text_small {
  font-size: 0.833rem;
}
p {
  font-family: "Nunito Sans", sans-serif;
  margin-bottom: 1rem;
  line-height: 1.6;
  color: var(--color-paragraph);
  font-weight: 400;
}

/* ================GENERAL STYLES=============== */
.pb {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: 70px;
  background: linear-gradient(90deg, #ffd960 -3.88%, #ffd13f 103.88%);
  color: var(--color-secondary);
  border-radius: 40px;
  order: 0;
  flex-grow: 0;
  font-family: "Raleway";
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  cursor: pointer;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  -ms-border-radius: 40px;
  -o-border-radius: 40px;
  border-color: var(--color-primary);
  padding: 24px 32px;
  margin: 0;
  box-shadow: -1px 1px 0px 0px #ffd13f;
}
.pb:hover {
  border-width: 0px 0px 4px 4px;
  border-style: solid;
  border-color: var(--color-secondary);
  color: var(--color-secondary);
}
.pb-container {
  margin: 32px 0;
  background-color: var(--color-secondary);
  border: 0.5px solid;
  border-color: var(--color-primary);
  border-radius: 40px;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  -ms-border-radius: 40px;
  -o-border-radius: 40px;
}
.primary-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 32px;
  margin: 32px 0;
  width: 188px;
  height: 70px;
  background: linear-gradient(90deg, #ffd960 -3.88%, #ffd13f 103.88%);
  border-radius: 12px;
  order: 0;
  flex-grow: 0;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  cursor: pointer;
}
.primary-btn:hover {
  background: var(--color-secondary);
  color: var(--color-primary);
  transition: var(--transition);
}
.primary-btn:disabled {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 32px;
  margin: 32px 0;
  width: 188px;
  height: 70px;
  background: linear-gradient(90deg, #ffd960 -3.88%, #ffd13f 103.88%);
  border-radius: 12px;
  order: 0;
  flex-grow: 0;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  opacity: 38%;
  cursor: not-allowed;
  color: black;
}
.secondary-btn {
  height: 70px;
  background: linear-gradient(
    90deg,
    rgba(255, 217, 96, 0.12) -3.88%,
    rgba(255, 209, 63, 0.12) 103.88%
  );
  border-radius: 12px;
  border: 1px solid var(--color-primary);
  font-family: "Nunito Sans";
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  cursor: pointer;
}
.section-title {
  text-align: center;
  text-transform: capitalize;
  margin-bottom: 60px;
}
.section-title h2 {
  font-size: 44px;
  font-weight: 700;
  line-height: 59px;
  letter-spacing: -0.02em;
  text-align: center;
  margin-bottom: 9.3px;
  color: #26272b;
}
.Home .section-title p {
  font-family: "Grillages";
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #26272b;
  opacity: 0.7;
}
.section-title p {
  font-size: 18px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--color-paragraph);
}

/*===================MEDIA QUERYIES (MEDIUM DEVICES)======================*/

@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-md);
  }
  .primary-btn {
    width: 95%;
  }
}

/*===================MEDIA QUERYIES (SMALL DEVICES)======================*/

@media screen and (max-width: 600px) {
  .container {
    width: var(--container-width-sm);
  }
  .primary-btn {
    width: 95%;
  }
  .section-title {
    margin-bottom: 0;
  }
  .section-title h2 {
    font-family: "Delius Unicase";
    font-size: 25px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0em;
    margin-bottom: 24px;
  }
  .section-title p {
    font-family: "Nunito Sans";
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    margin-bottom: 24px;
  }
}
.address{
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 40px;
  font-weight: 600;
  margin: 100px 0;
}
.address p {
    color: #927c4a !important;
}
.dropdown-item:hover {
  background-color: transparent;
}
.dropdown-item{
  padding: 0 !important;
}