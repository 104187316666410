.shipment-stepper {
  height: 100%;
}
.shipment-stepper em {
  display: none;
}
.step-progress {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.multi-step {
  width: 100%;
  height: 100%;
}
.progtrckr {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 56px;
  padding: 0;
  padding-top: 64px;
  gap: 140px;
}

.progtrckr ul,
.progtrckr ol {
  padding: 0;
}
.stepper-btn {
  border: none;
  border-radius: 16px;
  width: 12%;
  height: 64px;
  background: var(--color-primary);
  color: var(--color-secondary);
  margin: 10px;
}
.stepper-btn:disabled {
  margin-top: 32px;
  margin-bottom: 32px;
  padding: 24px 32px;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  width: 188px;
  height: 70px;
  opacity: 38%;
}
.primary-btn {
  border: none;
  border-radius: 16px;
  width: 400px;
  height: 64px;
  background: var(--color-primary);
  color: var(--color-secondary);
  margin: 0;
}
/* .primary-btn:hover {
  background: var(--color-secondary);
  color: var(--color-primary);
  transition: var(--transition);
} */
.shipment-method,
.export-country {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 48px;
  padding: 0 6%;
}
.import-country {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 60px;
  padding: 100px 0;
}
.choose-products-filled {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.choose-products-filled img {
  height: 100%;
}
.choose-products-filled p {
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--color-paragraph);
  margin-bottom: 5rem;
}
.footer-buttons {
  display: flex;
  align-items: center;
  padding: 0 6%;
}
.footer-buttons button {
  margin-top: 32px;
  margin-bottom: 32px;
  padding: 24px 32px;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  width: 188px;
  height: 70px;
}
#orderShipmentNote {
  display: none;
}
/*===================MEDIA QUERYIES (MEDIUM DEVICES)======================*/

@media screen and (max-width: 1024px) {
  .shipment-method {
    grid-template-columns: repeat(1, 1fr);
    gap: 24px;
  }
  .progtrckr {
    gap: 124px;
  }
  .step-progress p {
    display: none;
  }
}
/*===================MEDIA QUERYIES (SMALL DEVICES)======================*/

@media screen and (max-width: 600px) {
  .progtrckr {
    display: flex;
    align-items: center;
    padding: 0;
    gap: 24px;
  }

  .progtrckr span {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
  }
  .step-progress p {
    display: block;
    padding: 0 1rem 0 24px;
    margin-top: 19px;
    margin-bottom: 12px;
  }
  [dir="rtl"] .step-progress p {
    padding: 0 24px 0 1rem;
  }
  .export-country {
    grid-template-columns: repeat(1, 1fr);
    gap: 24px;
    padding: 0 24px;
  }
  .import-country {
    grid-template-columns: repeat(1, 1fr);
  }
  .stepper-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 24px;
    gap: 10px;
    width: 50%;
    height: 60px;
    background: linear-gradient(90deg, #ffd960 -3.88%, #ffd13f 103.88%);
    border-radius: 12px;
  }

  .shipment-method {
    gap: 24px;
  }
  #orderShipmentNote {
    display: block;
  }
}
