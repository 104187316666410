.dropbtn {
  background-color: transparent;
  color: var(--color-secondary);
  padding: 16px;
  font-size: 24px;
  border: none;
  transform: rotate(90deg);
}

.admin-shipment-product-card-dropdown {
  position: absolute;
  margin-top: -6em;
  margin-left: -36px;
  display: inline-block;
}

.admin-shipment-product-card-dropdown-content {
  display: none;
  position: absolute;
  background-color: var(--color-white);
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.admin-shipment-product-card-dropdown-content a {
  color: var(--color-secondary);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

.admin-shipment-product-card-dropdown-content a:hover {
  background-color: #ddd;
}

.admin-shipment-product-card-dropdown:hover
  .admin-shipment-product-card-dropdown-content {
  display: block;
}

.admin-shipment-product-card-dropdown:hover .dropbtn {
  background-color: transparent;
}

.admin-shipment-product-card {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  padding: 32px;
  width: 90%;
  height: 100%;
  background: var(--color-white);
  border: 1px solid var(--color-border);
  border-radius: 24px;
  box-shadow: -16px 13px 4px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 9px 0px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -16px 13px 4px 0px rgba(0, 0, 0, 0.75);
  margin-bottom: 50px;
}
.admin-shipment-product-card:hover {
  box-shadow: -16px 13px 4px 0px rgb(184, 145, 28);
  -webkit-box-shadow: -10px 9px 0px 0px rgb(184, 145, 28);
  -moz-box-shadow: -16px 13px 4px 0px rgb(184, 145, 28);
  transition: var(--transition);
}
.admin-shipment-product-card-h2 {
  grid-area: 1 / 5 / 6 / 7;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.admin-shipment-product-card-h2 img {
  width: 80%;
  height: 80%;
}
.admin-shipment-product-card-h1 {
  grid-area: 1 / 1 / 6 / 5;
}
.admin-shipment-product-card-h1 .admin-shipment-product-card-title {
  grid-area: 1 / 1 / 3 / 5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.admin-stockOff-btn {
  position: relative;
  width: 100% !important;
  height: 100% !important;
  background: var(--color-warning) !important;
  border-radius: 14px !important;
  padding: 5%;
}
.stockOff-btn:hover {
  background: var(--color-secondary) !important;
  color: var(--color-warning) !important;
}
.admin-shipment-product-card-h1
  .admin-shipment-product-card-title
  .admin-shipment-product-card-title-btn
  button {
  width: 100%;
  height: 100%;
  background: var(--color-primary);
  border-radius: 14px;
}
.admin-shipment-product-card-h1
  .admin-shipment-product-card-title
  .admin-shipment-product-card-title-btn
  button
  img {
  padding: 5px;
  margin: 5px;
}
.admin-shipment-product-card-h1
  .admin-shipment-product-card-title
  .admin-shipment-product-card-title-btn
  button:hover {
  color: var(--color-primary);
  background: var(--color-secondary);
  transition: var(--transition);
}
.admin-shipment-product-card-h1
  .admin-shipment-product-card-title
  .admin-shipment-product-card-title-btn
  button:hover
  img {
  background: var(--color-primary);
  border-radius: 50%;
}

.admin-shipment-product-card-h1 .admin-shipment-product-card-details {
  grid-area: 3 / 1 / 6 / 5;
  display: flex;
  width: 100%;
}
.admin-info-data {
  font-size: 15px;
}
.admin-shipment-product-card-h1
  .admin-shipment-product-card-details
  .admin-info-data {
  width: 70%;
}
.admin-shipment-product-card-h1
  .admin-shipment-product-card-details
  .admin-info-data {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.admin-shipment-product-card-h1
  .admin-shipment-product-card-details
  .admin-info-data
  .col {
  color: var(--color-paragraph);
}

.admin-shipment-product-card-h1
  .admin-shipment-product-card-details
  .admin-shipment-product-card-qty {
  width: 30%;
}
.admin-shipment-product-card-h1
  .admin-shipment-product-card-details
  .admin-shipment-product-card-qty
  input {
  width: 50px;
  text-align: center;
}
.admin-shipment-product-card-h1
  .admin-shipment-product-card-details
  .admin-shipment-product-card-qty
  .btn-group {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 4px;
  gap: 10px;
  width: 176px;
  height: 70px;
  border: 1px solid var(--color-primary);
  border-radius: 8px;
}
.admin-shipment-product-card-h1
  .admin-shipment-product-card-details
  .admin-shipment-product-card-qty
  .btn-group
  button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 6px;
  color: var(--color-secondary);
  background: var(--color-primary);
  border-radius: 50%;
}
.admin-shipment-product-card-h1
  .admin-hipment-product-card-details
  .admin-shipment-product-card-qty
  .btn-group
  button:hover {
  color: var(--color-primary);
  background: var(--color-secondary);
  transition: var(--transition);
}

/*===================MEDIA QUERYIES (MEDIUM DEVICES)======================*/

@media screen and (max-width: 1024px) {
  .admin-shipment-product-card-h2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .admin-shipment-product-card-h2 img {
    width: 75%;
    height: 80%;
  }
}

/*===================MEDIA QUERYIES (SMALL DEVICES)======================*/

@media screen and (max-width: 600px) {
  .admin-shipment-product-card {
    display: flex;
    flex-direction: column;
  }
  .admin-shipment-product-card-h2 img {
    height: 100%;
    width: 100%;
  }
  .admin-shipment-product-card-h1
    .admin-shipment-product-card-title
    .admin-shipment-product-card-title-btn
    button {
    height: 100%;
  }
  .admin-shipment-product-card-h1
    .admin-shipment-product-card-details
    .admin-shipment-product-card-qty
    .btn-group {
    width: 100%;
  }
  .admin-shipment-product-card-h1
    .admin-shipment-product-card-details
    .admin-shipment-product-card-qty {
    width: 100%;
    padding: 30px 0;
  }
  .admin-shipment-product-card-h1 .admin-shipment-product-card-details {
    display: flex;
    flex-direction: column;
  }
  .admin-shipment-product-card-h1
    .admin-shipment-product-card-details
    .admin-info-data {
    width: 100%;
  }
}
