/*
=============== 
Agent OTP Check Styles
===============
*/
.OTP-bg {
  background: url("../../../assets/img/Pages/Agent/slider.png") no-repeat center
    center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100vw;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  justify-content: center;
}
.OTP-FH {
  background: var(--color-heading-variant);
  height: 60em;
  display: flex;
  align-items: center;
  justify-content: center;
}
.OTP-FH .shapeImg {
  position: absolute;
  top: 62em;
  left: 1em;
  width: 15%;
  height: 25%;
}
.OTP-FH-form {
  background: var(--color-white);
  width: 75%;
  padding: 10%;
  border-radius: 15px;
  box-shadow: -16px 13px 4px 0px rgb(0, 0, 0);
  -webkit-box-shadow: -16px 13px 4px 0px rgb(0, 0, 0);
  -moz-box-shadow: -16px 13px 4px 0px rgb(0, 0, 0);
  border: 3px solid var(--color-secondary);
}
.OTP-FH-form input {
  width: 20%;
}
.formComponents .form-inputs {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 10% 0;
}
.formComponents .form-inputs input {
  margin: 0 10px;
}
.OTP-FH-form h2 {
  margin-bottom: 15%;
}

.OTP-FH-form .formComponents .email-icon {
  position: absolute;
  right: 67%;
  margin-top: -25px;
  cursor: pointer;
  color: var(--color-border);
}
.OTP-FH-form .OTP-btn {
  width: 70%;
  background: var(--color-primary);
  border: none;
  color: var(--color-secondary);
  margin: 10% 15%;
}
.OTP-FH-form a {
  text-decoration: none;
}
.OTP-FH-form .OTP-btn:hover {
  color: var(--color-primary);
  background: var(--color-secondary);
  transition: all 0.5s ease-in-out;
}
.OTP-SH {
  background: var(--color-secondary-variant);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.OTP-SH img:nth-child(2) {
  position: relative;
  top: -40em;
  left: 50%;
}
/*===================MEDIA QUERYIES (MEDIUM DEVICES)======================*/

@media screen and (max-width: 1024px) {
  .OTP-bg {
    display: block;
  }
  .OTP-SH {
    display: none;
  }
}

/*===================MEDIA QUERYIES (SMALL DEVICES)======================*/

@media screen and (max-width: 600px) {
}
