.shipment-products-container {
  width: 90%;
}
.shipment-products {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0 100px 0;
}
.shipment-products-cards {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px 0;
  height: 100%;
  width: 100%;
  border-radius: 24px;
  padding: 2%;
}
.shipment-products-category {
  width: 100%;
  margin: 0;
}
.shipment-products-category-flag {
  height: 60px;
  background: var(--color-white);
  border-radius: 32px 0px 0px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  float: right;
  margin-top: 30px;
  margin-bottom: 50px;
  margin-right: 30px;
  padding: 16px;
  box-shadow: 10px 10px 0px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 10px 10px 0px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 0px 0px rgba(0, 0, 0, 0.75);
}
/*===================MEDIA QUERYIES (MEDIUM DEVICES)======================*/

@media screen and (max-width: 1024px) {
}

/*===================MEDIA QUERYIES (SMALL DEVICES)======================*/

@media screen and (max-width: 600px) {
  .shipment-products-cards h2 {
    font-size: 1.9rem;
  }
  .shipment-products-cards {
    gap: 24px;
  }
  .shipment-products-container {
    width: 100%;
    padding: 0 24px;
  }
}
