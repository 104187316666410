.agent-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px;
  gap: 16px;
  width: 80%;
  height: 100%;
  background: var(--color-heading);
  border: 2px solid var(--color-secondary);
  border-radius: 24px;
  box-shadow: -16px 13px 4px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -16px 13px 0px 0px rgba(0, 0, 0, 1);
  -moz-box-shadow: -16px 13px 4px 0px rgba(0, 0, 0, 0.75);
}
.info-form h1 {
  font-size: 24px;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: center;
}
.info-form p {
  font-family: "Tajawal";
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0em;
  color: var(--color-secondary);
}
.info-form form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.info-form form input {
  width: 90%;
  margin-bottom: 60px;
}
.agent-info .btn-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.agent-info .btn-group button {
  border-radius: 12px;
  height: 56px;
  width: 226px;
  left: 185px;
  top: 558px;
  border-radius: 12px;
}
.agent-info .btn-group button:hover {
  background: linear-gradient(90deg, #ffd960 -3.88%, #ffd13f 103.88%);
  transition: var(--transition);
}
/*===================MEDIA QUERYIES (MEDIUM DEVICES)======================*/

@media screen and (max-width: 1024px) {
}
/*===================MEDIA QUERYIES (SMALL DEVICES)======================*/

@media screen and (max-width: 600px) {
  .info-form form {
    grid-template-columns: repeat(1, 1fr);
  }
}
