.AgentVerificationPopUp-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/*===================MEDIA QUERYIES (MEDIUM DEVICES)======================*/

@media screen and (max-width: 1024px) {
}
/*===================MEDIA QUERYIES (SMALL DEVICES)======================*/

@media screen and (max-width: 600px) {
}
