.payments {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;

  background: url("../../assets/slider_9.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.payments-sidebar {
  grid-area: 1 / 1 / 6 / 2;
  background: var(--color-secondary);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  padding: 50px 0;
  overflow: hidden;
  overflow-y: auto;
}
.payments-sidebar::-webkit-scrollbar {
  width: 10px;
}
.payments-sidebar::-webkit-scrollbar-thumb {
  border-radius: 10px;
}
.payments-sidebar a {
  text-decoration: none;
  color: var(--color-secondary);
}

.payments-sidebar a svg {
  width: 24px;
  height: 24px;
  margin: 0 10px;
  color: var(--color-heading);
}

.payments-sidebar .nav-menu-items li:hover a,
.payments-sidebar .nav-menu-items li:hover a svg,
.payments-sidebar .nav-menu-items li:hover a span {
  color: var(--color-primary);
  border-radius: 12px;
  transition: all 0.3s ease-in-out;
}
.payments-sidebar .nav-menu-items li span {
  margin: 10px 0;
  color: var(--color-heading);
  display: flex;
  align-items: center;
  justify-content: center;
}
.payments-sidebar .nav-menu-items li:hover {
  width: 100%;
}
.payments-sidebar .nav-menu-items li span:hover {
  color: var(--color-primary);
  transition: var(--transition);
}
.payments-content {
  grid-area: 1 / 2 / 6 / 6;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.904);
}
.nav-menu {
  background-color: var(--color-secondary);
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 100%;
  transition: 850ms;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
  /* background: var(--color-primary-variant); */
}

.nav-text {
  display: flex;
  align-items: center;
  list-style: none;
  height: 60px;
}
.nav-text img {
  margin: 0 10px;
  height: 100%;
}
.nav-text a {
  text-decoration: none;
  color: var(--color-heading);
  font-size: 16px;
  display: flex;
  align-content: center;
  justify-content: center;
}

.nav-menu-items {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 20px;
}

.navbar-toggle {
  background-color: var(--color-secondary);
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
}

/*===================MEDIA QUERYIES (MEDIUM DEVICES)======================*/

@media screen and (max-width: 1024px) {
  .payments {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }

  .payments-sidebar {
    grid-area: 1 / 1 / 2 / 6;
    background-size: auto;
    height: 100%;
    padding-bottom: 0;
  }
  .payments-content {
    grid-area: 2 / 1 / 6 / 6;
  }
  .nav-menu-items {
    height: 15rem;
    overflow-x: hidden;
    overflow-y: auto;
  }
}
/*===================MEDIA QUERYIES (SMALL DEVICES)======================*/

@media screen and (max-width: 600px) {
  .payments {
    grid-template-columns: repeat(1, 1fr);
  }
  .nav-text a {
    gap: 10px;
    align-items: center;
  }
  .nav-menu-items li {
    padding-bottom: 0px;
  }
  .nav-menu-items2 {
    background: black;
    position: fixed;
    height: 90.2px;
    width: 100%;
    bottom: 0;
    background: #121212;
    border-radius: 24px 24px 0px 0px;
    z-index: 999;
    margin-bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    padding: 0 24px;
    gap: 16.3px;
    font-family: "Nunito Sans";
  }
  .nav-menu-items2 li {
    /* padding-bottom: 16px; */
    width: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .nav-menu-items2 li a {
    flex-direction: column;
    width: 100%;
  }
  .payments-sidebar {
    padding: 0;
  }
  .payments-sidebar a svg:active {
    color: var(--color-primary);
  }
  .payments-sidebar .nav-menu-items2 li span {
    font-size: 12px;
    margin: 0;
    white-space: nowrap;
    overflow-x: scroll;
    display: flex;
    flex-direction: column;
    display: block;
    width: 100%;
    text-align: center;
  }
  .nav-text a:hover {
    background: none;
  }
  .payments-sidebar .nav-menu-items2 li:hover a,
  .payments-sidebar .nav-menu-items2 li:hover a svg,
  .payments-sidebar .nav-menu-items2 li:hover a span {
    width: 100px;
  }
}
