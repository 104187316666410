.contacts {
  display: flex;
  flex-direction: column;
}
.contacts .add-contacts {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.contacts .add-contacts form {
  display: flex;
  flex-direction: column;
  width: 100%;
}
#message,
.contacts .add-contacts form input {
  padding: 10px;
  width: 50%;
  border: 1px solid #ddd;
}
