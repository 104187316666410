.stepper-label {
  height: 100px;
  width: 12em;
  border-radius: 12px;
  padding: 1%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 12px;
  position: relative;
}
.stepper-label::after {
  content: "";
  position: absolute;
  height: 2px;
  width: 60%;
  left: 12rem;
  top: 43px;
  border: 1px dotted var(--color-primary);
  background: var(--color-secondary);
}
[dir="rtl"] .stepper-label::after {
  left: -8rem;
}
.progtrckr .progtrckr-todo:last-child .stepper-label::after {
  display: none;
}
.stepper-label-active {
  height: 100px;
  width: 12em;
  border-radius: 12px;
  padding: 1%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: var(--color-heading);
  box-shadow: 0px 12px 24px rgba(200, 171, 82, 0.18);
  border: 1px solid var(--color-primary);
}
.stepper-label-active::after {
  content: "";
  position: relative;
  height: 2px;
  width: 60%;
  left: 12rem;
  top: -2.9rem;
  border: 1px dotted var(--color-primary);
  background: var(--color-secondary);
}
[dir="rtl"] .stepper-label-active::after {
  left: -13rem;
}
.progtrckr .progtrckr-doing:last-child .stepper-label-active::after {
  display: none;
}
.stepper-label-active img {
  background: rgba(255, 243, 188, 0.7);
  border-radius: 24px;
  padding: 4%;
  width: 3em;
}
.stepper-label img {
  width: 40px;
}
@media screen and (min-width: 1444px) {
  .stepper-label img {
    width: 40px;
  }

  .stepper-label-active img {
    width: 48px;
  }
  .stepper-label-active span {
    font-size: 18px;
    padding: 5px;
  }
}
@media screen and (max-width: 768px) {
  .stepper-label-active {
    height: 100%;
  }
  .stepper-label::after {
    content: "";
    position: absolute;
    height: 2px;
    width: 12%;
    left: 12rem;
    top: 43px;
    border: 1px dotted var(--color-primary);
    background: var(--color-secondary);
  }
  .stepper-label-active::after {
    content: "";
    position: relative;
    height: 2px;
    width: 12%;
    left: 8rem;
    top: -2.9rem;
    border: 1px dotted var(--color-primary);
    background: var(--color-secondary);
  }
  .progtrckr {
    gap: 14px;
  }
}
@media screen and (max-width: 576px) {
  .progtrckr {
    /* margin: 0 !important;
    gap: 0 !important;
    font-family: "Nunito Sans";
    justify-content: flex-end;
    position: absolute;
    right: 0;
    top: 6rem; */
    display: none;
  }
  [dir="rtl"] .progtrckr {
    left: 0;
  }
  .stepper-label {
    width: 100%;
    display: none;
  }
  .stepper-label-active {
    height: 100%;
    width: 8rem;
    background: rgba(255, 243, 188, 0.7);
    box-shadow: 0px 6px 24px rgba(41, 45, 50, 0.08);
    border-radius: 25px 0px 0px 25px;
  }
  [dir="rtl"] .stepper-label-active {
    border-radius: 0px 25px 25px 0px;
  }
  .stepper-label-active img {
    width: 24px;
    height: 24px;
    padding: 0;
  }
  .stepper-label-active::after {
    display: none;
  }
  .stepper-label::after {
    left: 6.5rem;
    top: 48px;
  }
  .stepper-label img {
    width: 24px;
    height: 24px;
  }
}
