.password {
  display: flex;
  position: relative;
}
.password span {
  position: absolute;
  top: 5px;
  right: 14px;
  cursor: pointer;
}
.password span svg {
    color:#ffc107 ;
    font-size: 18px;
    cursor: pointer;

}