.admin-cart-container {
  width: 90%;
  padding: 4%;
  margin: 20px auto;
  background: var(--color-white);
  border-radius: 24px;
}
.admin-cart-container small {
  margin-top: 56px;
  margin-bottom: 70px;
}
.admin-cart-items {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* cart summary */
.admin-cart-summary {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 1px solid rgb(187, 187, 187);
  padding-top: 2rem;
  padding-bottom: 100px;
}
.admin-cart-summary .updateAdminCart-btn {
  margin: 56px 0;
  height: 100%;
  width: fit-content;
  padding: 3%;
}
.admin-cart-summary .updateAdminCart-btn:disabled {
  border-radius: 12px;
}
.admin-cart-summary .clear-btn {
  height: 40px;
  border-radius: 5px;
  font-weight: 400;
  letter-spacing: 1.15px;
  border: 0.5px solid rgb(177, 177, 177);
  color: gray;
  background: none;
  outline: none;
  cursor: pointer;
  margin: 0;
}
.admin-cart-checkout {
  width: 270px;
  max-width: 100%;
}
.admin-cart-checkout .subtotal {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
}
.admin-cart-checkout .amount {
  font-weight: 700;
}
.admin-cart-checkout p {
  font-size: 14px;
  font-weight: 200;
  margin: 0.5rem 0;
}
.admin-cart-checkout button {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  font-weight: 400;
  letter-spacing: 1.15px;
  background-color: var(--color-primary);
  border: none;
  outline: none;
  cursor: pointer;
}
.admin-start-shopping button {
  margin-bottom: 30px;
}
.admin-continue-shopping {
  margin-top: 1rem;
}
.admin-continue-shopping a {
  text-decoration: none;
  display: flex;
  align-items: center;
  font-size: 20px;
}
.admin-continue-shopping a span {
  margin-left: 0.5rem;
}
.admin-cart-empty {
  font-size: 20px;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/*===================MEDIA QUERYIES (MEDIUM DEVICES)======================*/

@media screen and (max-width: 1024px) {
}

/*===================MEDIA QUERYIES (SMALL DEVICES)======================*/

@media screen and (max-width: 600px) {
}
