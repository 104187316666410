.notifications {
  position: absolute;
  top: 5.1rem;
  right: 1em;
  display: flex;
  align-items: center;
  padding: 1%;
  background: white;
  border-radius: 8px;
  border: 1px solid var(--color-primary);
  height: 20rem;
  width: 50rem;
  overflow: hidden;
  overflow-y: auto;
  z-index: 1;
  display: block;
}
[dir="rtl"] .notifications {
  position: absolute;
  top: 5.1rem;
  left: 0;
  right: 64%;
  display: flex;
  flex-direction: column;
  padding: 1%;
  background: white;
  border-radius: 8px;
  border: 1px solid var(--color-primary);
  height: 20rem;
  width: 35rem;
  overflow: hidden;
  overflow-y: auto;
  z-index: 1;
  gap: 12px;
}
.notification {
  display: flex;
  align-items: center;
  border: 1px solid var(--color-paragraph);
  border-radius: 8px;
  padding: 3%;
  margin-bottom: 12px;
  width: 100%;
}
.notificationClick:hover {
  cursor: pointer;
  border: 2px solid var(--color-primary);
  transition: 0.3s ease;
}
.notification .notification-img {
  border-radius: 100%;
  border: 2px solid var(--color-secondary);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.notification .notification-img img {
  width: 100%;
  height: 100%;
}
.notification-info {
  width: 100%;
}
.notification .notification-info div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.notification .notification-info div span:nth-of-type(1) {
  font-size: 16px;
}
.notification .notification-info div span:nth-of-type(2) {
  color: var(--color-paragraph);
}
.notification .notification-info p {
  color: var(--color-paragraph);
}
/*===================MEDIA QUERYIES (MEDIUM DEVICES)======================*/

@media screen and (max-width: 1024px) {
  .notifications {
    right: 10em;
    top: 25em;
  }
}

/*===================MEDIA QUERYIES (SMALL DEVICES)======================*/

@media screen and (max-width: 600px) {
  .notifications {
    width: 90%;
    right: 1.5rem;
    top: 28rem;
  }
}
