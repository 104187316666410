.export-country-card {
  display: flex;
  flex-direction: column;
  align-items: center;

  border-radius: 16px;
  background: var(--color-white);
  padding: 4%;
  cursor: pointer;
  box-shadow: 0px 6px 24px rgba(41, 45, 50, 0.08);
}
.export-country-card:hover {
  border: 2px solid var(--color-primary);
  transition: var(--transition);
  cursor: pointer;
}
.export-country-card span {
  display: block;
}
.export-country-card .export-country-card-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0 0 30px 10px;
}
.export-country-card .export-country-card-header span {
  padding-left: 8px;
}
.export-country-card .export-country-card-header img {
  width: 40px;
}
.export-country-card-body {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  height: fit-content;
  width: 100%;
  height: 25rem;
  overflow: hidden;
  overflow-y: auto;
  gap: 18px 0;
}
.export-country-card-body .export-card-body-category {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.export-country-card-body .export-card-body-category img {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  border: 1px solid var(--color-primary);
}
.export-country-card-body .export-card-body-category span {
  text-align: center;
  font-size: 14px;
}
#soongIMg {
  width: 100%;
}
/*===================MEDIA QUERYIES (MEDIUM DEVICES)======================*/

@media screen and (max-width: 1024px) {
  .export-country-card-body {
    grid-template-columns: repeat(2, 1fr);
  }
}
/*===================MEDIA QUERYIES (SMALL DEVICES)======================*/

@media screen and (max-width: 600px) {
  .export-country-card-body {
    grid-template-columns: repeat(3, 1fr);
  }
  .export-country-card-body .export-card-body-category {
    font-family: "Nunito Sans";
  }
  .export-country-card-body .export-card-body-category img {
    width: 80px;
    height: 80px;
  }
}
