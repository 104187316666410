.country-langs {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 30px;
  justify-content: center;
  align-items: center;
}
/* .add-import{
  width: fit-content;
  margin-top: 30px;
} */