.rating {
  width: 100%;
  text-align: center;
}
.rating-container-card-header {
  display: flex;
  align-items: center;
}

.rating-container-card-header-img img {
  height: 108.9473648071289px;
  width: 108.9473648071289px;
  left: 885.0263671875px;
  top: 384.025390625px;
  border-radius: 50%;
}
.rating-container-card-header-info {
  margin: 0 33px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.rating-container-card-header-info h2 {
  font-size: 31px;
  font-weight: 700;
  line-height: 49px;
  letter-spacing: 0em;
  text-align: right;
}
.rating-container-card-header-info p {
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  color: var(--color-paragraph);
}
.rating-container-card {
  box-sizing: border-box;
  width: 100%;
  padding: 5%;

  background: var(--color-white);
  border: 3px dashed var(--color-primary-variant);
  box-shadow: -4px -4px 8px rgba(18, 18, 18, 0.04);
  border-radius: 12px;
}
.rating-container-card-stars {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 70px;
  margin-bottom: 65px;
}
.rating-container-card-body textarea {
  box-sizing: border-box;
  width: 90%;
  height: 122px;
  border-width: 1px 1px 4px 1px;
  border-style: solid;
  border-color: rgba(112, 112, 112, 0.32);
  border-radius: 12px;
  padding: 2%;
  margin-bottom: 50px;
}
.rating-container-card-btns {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.rating-container-card-btns button {
  margin-bottom: 30px;
}
.rating-container-card-btns .showratings {
  background: transparent;
}

/*===================MEDIA QUERYIES (SMALL DEVICES)======================*/

@media screen and (max-width: 600px) {
  .rating-component-modal-body img {
    width: auto;
  }
  .react-simple-star-rating-tooltip {
    display: none;
  }
}
