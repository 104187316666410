.dropbtn {
  background-color: transparent;
  color: var(--color-secondary);
  padding: 16px;
  font-size: 24px;
  border: none;
  transform: rotate(90deg);
}

.shipment-product-card-dropdown {
  position: absolute;
  margin-top: -6em;
  margin-left: -36px;
  display: inline-block;
}

.shipment-product-card-dropdown-content {
  display: none;
  position: absolute;
  background-color: var(--color-white);
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.shipment-product-card-dropdown-content a {
  color: var(--color-secondary);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

.shipment-product-card-dropdown-content a:hover {
  background-color: #ddd;
}

.shipment-product-card-dropdown:hover .shipment-product-card-dropdown-content {
  display: block;
}

.shipment-product-card-dropdown:hover .dropbtn {
  background-color: transparent;
}

.shipment-product-card {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  padding: 32px 32px 32px 0;
  background: var(--color-white);
  border: 1px solid var(--color-border);
  border-radius: 24px;
  box-shadow: -16px 13px 4px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 9px 0px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -16px 13px 4px 0px rgba(0, 0, 0, 0.75);
  width: 100%;
}
.shipment-product-card:hover {
  box-shadow: -16px 13px 4px 0px rgb(184, 145, 28);
  -webkit-box-shadow: -10px 9px 0px 0px rgb(184, 145, 28);
  -moz-box-shadow: -16px 13px 4px 0px rgb(184, 145, 28);
  transition: var(--transition);
}
.shipment-product-card-h2 {
  grid-area: 1 / 1 / 5 / 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
}
.shipment-product-card-h2 img {
  width: 10em;
  height: 10em;
}
.shipment-product-card-h1 {
  grid-area: 1 / 2 / 5 / 8;
  padding: 0 1rem;
}
.shipment-product-card-h1 .shipment-product-card-title {
  grid-area: 1 / 3 / 3 / 8;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.shipment-product-card-h1 .shipment-product-card-title div:first-of-type {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 31px;
  position: relative;
  left: 1.5rem;
  height: 4.9rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.stockOff-btn {
  position: relative;
  width: 100% !important;
  height: 100% !important;
  background: var(--color-warning) !important;
  border-radius: 14px !important;
  padding: 5%;
}
.stockOff-btn:hover {
  background: var(--color-secondary) !important;
  color: var(--color-warning) !important;
}
.shipment-product-card-h1
  .shipment-product-card-title
  .shipment-product-card-title-btn
  button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 16px 24px;
  width: 100%;
  background: linear-gradient(90deg, #ffd960 -3.88%, #ffd13f 103.88%);
  border-radius: 12px;
  font-family: "Nunito Sans";
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  margin: 0 !important;
}
.shipment-product-card-h1
  .shipment-product-card-title
  .shipment-product-card-title-btn
  button
  img {
  padding: 5px;
  margin: 5px;
}
.shipment-product-card-h1
  .shipment-product-card-title
  .shipment-product-card-title-btn
  button:hover {
  color: var(--color-primary);
  background: var(--color-secondary);
  transition: var(--transition);
}
.shipment-product-card-h1
  .shipment-product-card-title
  .shipment-product-card-title-btn
  button:hover
  img {
  background: var(--color-primary);
  border-radius: 50%;
}

.shipment-product-card-h1 .shipment-product-card-details {
  grid-area: 3 / 1 / 6 / 5;
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.info-data {
  font-size: 15px;
}
.info-data .row {
  margin: 10px -24px;
}

.shipment-product-card-h1 .shipment-product-card-details .info-data {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 45px;
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0em;
  width: 30rem;
}

.shipment-product-card-h1 .shipment-product-card-details .info-data .col {
  color: var(--color-paragraph);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.shipment-product-card-h1
  .shipment-product-card-details
  .shipment-product-card-qty {
  width: 30%;
}
.shipment-product-card-h1
  .shipment-product-card-details
  .shipment-product-card-qty
  input {
  width: 50px;
  text-align: center;
}
.shipment-product-card-h1
  .shipment-product-card-details
  .shipment-product-card-qty
  .btn-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 4px;
  gap: 10px;
  height: 70px;
  border: 1px solid var(--color-primary);
  border-radius: 8px;
}
.shipment-product-card-h1
  .shipment-product-card-details
  .shipment-product-card-qty
  .btn-group
  button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 6px;
  color: var(--color-secondary);
  background: var(--color-primary);
  border-radius: 50%;
}
.shipment-product-card-h1
  .shipment-product-card-details
  .shipment-product-card-qty
  .btn-group
  button:hover {
  color: var(--color-primary);
  background: var(--color-secondary);
  transition: var(--transition);
}
.shipment-product-card-qty p {
  margin-bottom: 8px;
  height: 22px;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-transform: capitalize;
  color: #121212;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

#deleteImg {
  position: absolute;
  width: 2rem;
  height: 2rem;
  top: -1rem;
  left: 1rem;
  cursor: pointer;
}
/*===================MEDIA QUERYIES (MEDIUM DEVICES)======================*/

@media screen and (max-width: 1024px) {
  .shipment-product-card-h2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .shipment-product-card-h2 img {
    width: 80%;
    height: 80%;
  }
}

/*===================MEDIA QUERYIES (SMALL DEVICES)======================*/

@media screen and (max-width: 600px) {
  .shipment-product-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px 7px 0px 8px;
    gap: 16px;
    width: 342px;
    height: 300px;
    background: #ffffff;
    border: 1px solid #4b4b4b;
    border-radius: 12px;
    position: relative;
  }
  .shipment-product-card-h2 {
    position: absolute;
  }
  .shipment-product-card-h2 img {
    width: 70px;
    height: 66px;
  }
  .shipment-product-card-h1 {
    padding: 0 !important;
    width: 100%;
    height: 100%;
  }
  .shipment-product-card-h1
    .shipment-product-card-title
    .shipment-product-card-title-btn
    button {
    height: 100%;
  }
  .shipment-product-card-h1
    .shipment-product-card-details
    .shipment-product-card-qty
    .btn-group {
    width: 100%;
  }
  .shipment-product-card-h1
    .shipment-product-card-details
    .shipment-product-card-qty {
    width: 100%;
  }
  .shipment-product-card-h1 .shipment-product-card-details {
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
    top: 12px;
  }
  .shipment-product-card-h1 .shipment-product-card-details .info-data {
    display: flex;
    width: 100%;
    gap: 0;
  }
  .shipment-product-card-h1
    .shipment-product-card-details
    .info-data
    > div:nth-child(1) {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 80%;
  }
  .shipment-product-card-h1 .shipment-product-card-title {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: end;
  }
  .shipment-product-card-title-info {
    text-align: center;
  }
  .shipment-product-card-title-info h5 {
    width: 232px;
    font-family: "Delius Unicase";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    margin: 0;
    color: #121212;
    text-align: start;
  }
  .shipment-product-card-title-info p {
    display: none;
  }
  #deleteImg {
    top: 1rem;
  }
  .shipment-product-card-qty p {
    font-family: "Nunito Sans";
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
  }
  .shipment-product-card-h1
    .shipment-product-card-details
    .shipment-product-card-qty
    .btn-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 12px;
    width: 153px;
    height: 48px;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
  }
  .shipment-product-card-h1
    .shipment-product-card-details
    .shipment-product-card-qty
    .btn-group
    button {
    margin: 0 !important;
  }
  .shipment-product-card-h1
    .shipment-product-card-title
    .shipment-product-card-title-btn
    button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 12px 24px;
    gap: 10px;
    width: 153px;
    height: 47px;
    background: linear-gradient(90deg, #ffd960 -3.88%, #ffd13f 103.88%);
    border-radius: 12px;
    position: absolute;
    top: 14.1rem;
    right: 7px;
    z-index: 99;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
  }
  .shipment-product-card-h1
    .shipment-product-card-title
    .shipment-product-card-title-btn
    button
    svg {
    width: 18px;
  }
  .info-data .row {
    padding: 0 !important;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
  }
  .info-data .row > .col:nth-child(1) {
    font-family: "Nunito Sans";
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    color: #121212;
  }
  .shipment-product-card-h1 .shipment-product-card-details .info-data .col {
    padding: 0 !important;
    display: block;
    width: 6rem;
  }

  #product-card-hr {
    width: 190px;
    position: absolute;
    left: 4.9rem;
    top: 5.5rem;
    margin: 0;
  }
}
