.deliveryMethod,
.containerStatus,
.importCountry,
.shipmentMethodCard {
  display: flex;
  flex-direction: column;
  align-content: center;
  height: 100%;
  width: 100%;
  border-radius: 16px;
  background: var(--color-white);
  box-shadow: 0px 6px 24px rgba(41, 45, 50, 0.08);
  padding: 5%;
}
.shipmentMethodCard .shipmentMethodCard-body div:nth-child(2) {
  margin-top: 52px;
  width: 100%;
}
.deliveryMethod-header,
.importCountry-header,
.shipmentMethodCard .shipmentMethodCard-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 32px;
}
.deliveryMethod .deliveryMethod-header span,
.containerStatus .containerStatus-header span,
.importCountry .importCountry-header span,
.shipmentMethodCard .shipmentMethodCard-header span {
  padding: 0 8px;
  font-size: 18px;
  font-weight: bolder;
  line-height: 19px;
  letter-spacing: 0em;
}

.importCountry .importCountry-body-dropdown select {
  font-family: "Montserrat Alternates", sans-serif;
  width: 100%;
  padding: 10px;
  margin-bottom: 16px;
  border: 1px solid var(--color-paragraph);
  border-radius: 12px;
  cursor: pointer;
}
[dir="rtl"] .importCountry .importCountry-body-dropdown select {
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
}
.shipmentMethodCard .shipmentMethodCard-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.shipmentMethodCard-body-buttons {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: flex-start;
  justify-content: space-evenly;
  height: 100%;
  width: 100%;
  gap: 16px;
}
.shipmentMethodCard-btn {
  font-size: 15px;
  font-family: "Montserrat Alternates", sans-serif;
  color: black;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 12px;
  background: var(--color-white);
  border-radius: 8px;
  border: 1px solid var(--color-border);
  padding: 2%;
  margin: 0 !important;
}

.shipmentMethodCard-btn:hover {
  border: 2px solid var(--color-primary);
}
#shippingContainer {
  width: 100%;
}
#importCountry-dropdown,
#importState-dropdown,
#container-dropdown,
#deliveryMethod-dropdown {
  font-family: "Montserrat Alternates", sans-serif;
  border-radius: 12px;
  border: 1px solid black;
  height: 56px;
  width: 100%;
}

.shipmentMethodCard .shipmentMethodCard-body div:nth-child(2) h4 {
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
}
.shipmentMethodCard .shipmentMethodCard-body div:nth-child(2) h4 {
  margin-bottom: 8px;
}
.shipmentMethodCard .shipmentMethodCard-body div:nth-child(2) p {
  font-size: 16px;
  color: var(--color-paragraph);
}

/* ================================================================== */
#importCountry-body-dropdown {
  background: var(--color-white);
  width: 100%;
  color: var(--color-secondary);
  text-align: start;
  border: 1px solid var(--color-paragraph);
  margin-top: 12px;
  padding: 10px;
}
.containerStatus-header {
  margin-bottom: 21px;
}
.containerStatus-body {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.containerStatus-body button {
  padding: 2%;
}
.containerStatus-body button:last-of-type {
  margin: 0 16px;
}
/*===================MEDIA QUERYIES (MEDIUM DEVICES)======================*/

@media screen and (max-width: 1024px) {
  #importCountry-body-dropdown {
    margin: 16px 0;
  }
  .shipmentMethodCard-body-buttons {
    grid-template-columns: repeat(1, 1fr);
  }
}
/*===================MEDIA QUERYIES (SMALL DEVICES)======================*/

@media screen and (max-width: 600px) {
  .deliveryMethod-header,
  .importCountry-header,
  .shipmentMethodCard .shipmentMethodCard-header {
    margin-bottom: 16px;
  }
  .shipmentMethodCard-body-buttons {
    gap: 20px;
  }
  .shipmentMethodCard-btn {
    text-align: center;
    position: relative;
    height: 56px;
  }
  .shipmentMethodCard-btn img {
    position: absolute;
    left: 0;
    width: 40px;
    margin: 8px;
  }
  .footer-buttons {
    display: flex;
  }
}
