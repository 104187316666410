@use "../scss/abstracts/" as *;

.main {
  overflow-x: hidden;
  width: 100%;

  @include mobile {
    padding-left: 0;
    width: 100vw;
  }

  &__content {
    min-height: 100vh;
    padding: $spacing;
    transition: all 0.5s ease-in-out;

    @include tablet {
      padding: $mobileSpacing;
      position: relative;
      background-color: $bodyBg;
      z-index: 100;
    }
  }
}
.main__content{
  transform: scale(1) translateX(0);
}
.sidebar-open .main__content {
  height: 100vh;
  overflow: hidden;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  transform: scale(0.8) translateX(50%) !important;
  transition: all 0.5s ease-in-out;
} 
[dir="rtl"] .main {
  padding-right: $sideBarWidth ;
}
[dir="ltr"] .main {
  padding-left: $sideBarWidth ;
}
[dir="rtl"] .main__content {
  z-index: 100;
}
.move{
  transform: scale(0.8) translateX(50%);
}
// @use "../scss/abstracts/" as *;

// .main {
//   overflow-x: hidden;
//   width: 100%;
//   @include tablet {
//     padding-left: 0;
//     width: 100vw;
//   }

//   &__content {
//     min-height: 100vh;
//     padding: $spacing;
//     transition: all 0.5s ease-in-out;

//     @include tablet {
//       padding: $mobileSpacing;
//       position: relative;
//       background-color: $bodyBg;
//       z-index: 100;
//     }
//   }
// }

// .sidebar-open .main__content {
//   height: 100vh;
//   overflow: hidden;
//   border-top-left-radius: 30px;
//   border-bottom-left-radius: 30px;
//   transform: scale(0.8) translateX(50%);
//   transition: all 0.5s ease-in-out;
// }
