/*
=============== 
footer Styles
===============
*/
.footer {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-content: center;
  color: aliceblue;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 13%,
    rgba(0, 0, 0, 1) 13%,
    rgba(0, 0, 0, 1) 50%,
    rgba(0, 0, 0, 1) 100%
  );
}
@media (min-width: 992px) {
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333% !important;
  }
}
.footer .container {
  border: 1px solid #faf7ef;
  /*padding: 0 37px;
  */
  border-radius: 100px 100px 0px 0px;
  -webkit-border-radius: 100px 100px 0px 0px;
  -moz-border-radius: 100px 100px 0px 0px;
  -ms-border-radius: 100px 100px 0px 0px;
  -o-border-radius: 100px 100px 0px 0px;
}
.footer .footer-qr {
  border-radius: 100px 100px 0px 0px;
  -webkit-border-radius: 100px 100px 0px 0px;
  -moz-border-radius: 100px 100px 0px 0px;
  -ms-border-radius: 100px 100px 0px 0px;
  -o-border-radius: 100px 100px 0px 0px;
  background: linear-gradient(
    90deg,
    var(--dark-two) 1.53%,
    var(--dark-one) 101.94%
  );
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 36px 42px;
  color: var(--light);
}
@media screen and (max-width: 1024px) {
  .footer-qr > img {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .footer .footer-qr {
    flex-direction: column;
    text-align: center;
  }
  .footer .footer-qr h3 {
    font-size: 26px !important;
  }
}
.footer .footer-qr img:nth-child(2) {
  max-width: 250px;
  max-height: 162px;
}
.footer .footer-qr .img-container {
  width: 162px;
  height: 162px;
}
.footer .footer-qr .img-container img {
  width: 100%;
  height: 100%;
}
.footer .qr-info p {
  font-family: Raleway;
  font-weight: 500;
}
.footer .qr-info h3 {
  font-family: Grillages;
  font-weight: 600;
  font-size: 36px;
  line-height: 43px;
  text-transform: uppercase;
  letter-spacing: 4px;
}
.footer-row {
  display: grid;
  padding: 0 24px;
  grid-template-columns: repeat(10, minmax(0, 1fr));
  gap: 8px;
  justify-content: space-between;
}
.footer-list-container {
  grid-column: span 2 / span 2;
}
.footer-location {
  grid-column: span 3 / span 3;
}
.footer-contact {
  grid-column: span 3 / span 3;
}
@media screen and (max-width: 1024px) {
  .footer-row {
    gap: 32px 8px;
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .footer-location {
    grid-column: span 2 / span 2;
  }
  .footer-contact {
    grid-column: span 2 / span 2;
  }
}
@media screen and (max-width: 600px) {
  .footer-row {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .footer-location {
    grid-column: span 2 / span 2;
  }
  .footer-contact {
    grid-column: span 2 / span 2;
  }
}
.footer-location > ul {
  padding: 0;
  font-size: 16px;
  gap: 8px;
}
.footer-location li {
  margin-bottom: 16px;
  font-weight: 500;
}
.footer h5 {
  font-family: "Grillages" !important;
  font-size: 20px !important;
  margin-bottom: 35px;
  font-weight: 400 !important;
}
.footer {
  font-family: Raleway !important;
}
.footer .row:not(:nth-child(1)) {
  padding: 0 36px;
}
.footer .row:nth-child(2) {
  padding-top: 12px;
}
.footer .footerLogo {
  width: 40%;
  /* float: left; */
}
.footer .footer-langs {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
}
.footer .footer-langs #basic-nav-dropdown {
  width: 262px;
  height: 54px;
  background: rgba(255, 255, 255, 0.22);
  border: 1px solid #ffffff;
  border-radius: 12px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: aliceblue;
  font-family: "Nunito Sans", sans-serif;
  padding: 0 16px;
}
.nav-link {
  color: #121212;
}
.nav-link:hover {
  color: #121212;
}
.footer-contact h5,
.footer-catalog h5,
.footer-list-container h5 {
  margin-bottom: 35px;
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0.075em;
}
.footer-list-container ul {
  padding: 0;
}
.footer-list-container ul li {
  margin-bottom: 16px;
}
.footer-list-container ul li a {
  font-family: Raleway;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.075em;
  color: var(--color-white);
}
.footer-tape a:hover,
.footer-list-container ul li a:hover {
  color: var(--color-primary);
}
.footer-contact-cards {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  gap: 40px;
}
.footer-contact-cards .footer-contact-card-1,
.footer-contact-cards .footer-contact-card-2 {
  width: 100%;
  height: 180px;
  background: rgba(255, 255, 255, 0.22);
  border: 1px solid #ffffff;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
  gap: 16px;
  padding: 30px;
}

.footer-contact-cards .footer-contact-card-1 div,
.footer-contact-cards .footer-contact-card-2 div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}
.footer-contact-cards
  .footer-contact-card-1
  div
  span:nth-of-type(1)
  svg
  .footer-contact-cards
  .footer-contact-card-1
  div
  span:nth-of-type(2)
  svg {
  width: 1rem;
  height: 100%;
}
.footer-contact-cards .footer-contact-card-1 div span:nth-of-type(2),
.footer-contact-cards .footer-contact-card-2 div span:nth-of-type(2) {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.075em;
}

.footer-contact-cards .footer-contact-card-1 div span:nth-of-type(2) a:hover,
.footer-contact-cards .footer-contact-card-2 div span:nth-of-type(2) a:hover,
.footer-contact-cards .footer-contact-card-1 div span:nth-of-type(2):hover,
.footer-contact-cards .footer-contact-card-2 div span:nth-of-type(2):hover {
  color: var(--color-primary);
  transition: 0.3s;
  cursor: pointer;
}
.footer-socials {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 36px;
  margin-bottom: 36px;
  position: relative;
}

.footer-socials a::before {
  content: " ";
  position: relative;
  left: 35px;
  top: 28px;
  display: block;
  height: 20px;
  width: 1px;
  background: var(--color-white);
}
[dir="rtl"] .footer-socials a::before {
  content: " ";
  position: relative;
  left: -37px;
  top: 24px;
  display: block;
  height: 20px;
  width: 1px;
  background: var(--color-white);
}
.footer-socials a:last-child::before {
  visibility: hidden;
}
.footer-socials img {
  width: 20px;
  height: 20px;
}
.copyright {
  text-align: center;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
  margin-bottom: 45px;
}
.copyright img {
  width: 20px;
  height: 20px;
  position: relative;
}
.footer-tape {
  width: 100%;
  height: 68px;
  background: #464646;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.075em;
  position: relative;
}

.footer-tape img {
  position: absolute;
  width: 49%;
  top: -21rem;
  right: -18rem;
  object-fit: contain;
}
[dir="rtl"] .footer-tape img {
  position: absolute;
  right: 61rem;
  top: -21rem;
  rotate: 77deg;
  z-index: 99;
  width: 49rem;
}
.dropdown-menu.show {
  width: 100% !important;
  background-color: #121212;
}
.dropdown-menu.show a {
  color: white;
  font-family: "Nunito Sans", sans-serif;
}
.dropdown-menu.show li a:hover {
  background: var(--color-primary);
  color: #121212;
}
/*===================MEDIA QUERYIES (Large DEVICES)======================*/

@media screen and (min-width: 1444px) {
  .footer-tape img {
    position: absolute;
    width: 49%;
    top: -30rem;
    right: -24rem;
    object-fit: contain;
  }
  [dir="rtl"] .footer-tape img {
    position: absolute;
    right: 87rem;
    rotate: 77deg;
    z-index: 99;
    width: 50rem;
  }
}
/*===================MEDIA QUERYIES (MEDIUM DEVICES)======================*/

@media screen and (max-width: 1024px) {
  .footer-tape img {
    position: absolute;
    width: 52%;
    top: -10rem;
    right: -11rem;
    object-fit: contain;
  }
  .footer-contact-cards {
    width: 100%;
  }
  .footer-contact-cards .footer-contact-card-1,
  .footer-contact-cards .footer-contact-card-2 {
    padding: 0;
    width: 100%;
  }
  .footer-contact-cards .footer-contact-card-1 div span:nth-of-type(2),
  .footer-contact-cards .footer-contact-card-2 div span:nth-of-type(2) {
    font-size: 12px;
  }
}

/*===================MEDIA QUERYIES (SMALL DEVICES)======================*/

@media screen and (max-width: 600px) {
  .footer .footer-langs {
    padding: 6%;
  }
  .footer .footer-langs #basic-nav-dropdown {
    width: 100%;
    font-size: 12px;
    color: aliceblue;
  }
  .footer .footerLogo {
    width: 100%;
  }
  .footer-contact,
  .footer-catalog,
  .footer-list-container {
    padding: 0 24px;
  }
  .footer-contact {
    padding-top: 40px;
  }
  .footer-socials {
    padding-top: 50px;
  }
  .footer-tape a {
    font-family: "Nunito Sans";
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.075em;
  }
}
