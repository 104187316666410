.search .search-inputs {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 50px;
  border-radius: 10px;
  cursor: pointer;
  font-family: "Nunito Sans" !important;
}
[dir="rtl"] .search .search-inputs {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 0%;
  background: black;
  height: 50px;
  border-radius: 10px;
  padding: 9px;
  cursor: pointer;
  font-family: "Nunito Sans";
}
/* .search .search-inputs:hover #searchInput {
  width: 280px;
  background: var(--color-white);
  border-radius: 24px;
  padding: 0.7rem;
} */
.search .search-inputs #searchInput {
  border: none;
  background: none;
  outline: none;
  padding: 0;
  color: var(--color-secondary);
  font-size: 16px;
  transition: 0.4s;
  width: 0px;
  font-family: "Nunito Sans";
}
.searchIcon {
  float: right;
  width: 40px;
  height: 40px;
  position: relative;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.4s;
}
.searchIcon img {
  width: 24px;
  height: 24px;
}
.dataResults {
  position: absolute;
  top: 5.1rem;
  right: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2%;
  background: white;
  border-radius: 2px;
  border: 1px solid var(--color-primary);
  height: 20em;
  overflow: hidden;
  overflow-y: auto;
  z-index: 1;
}
[dir="rtl"] .dataResults {
  position: absolute;
  top: 8em;
  right: 62em;
  left: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2%;
  background: white;
  border-radius: 2px;
  border: 1px solid var(--color-primary);
  height: 20em;
  overflow: hidden;
  overflow-y: auto;
}
.dataResults::-webkit-scrollbar {
  width: 10px;
}
.dataResults::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.555);
  border-radius: 10px;
}
.dataResults a {
  text-decoration: none;
  color: var(--color-secondary);
}
.dataResults a:hover {
  color: var(--color-primary);
  border-bottom: 1px solid var(--color-primary);
  transition: all 0.3s ease-in-out;
}
#clearBtn {
  cursor: pointer;
}
/*===================MEDIA QUERYIES (MEDIUM DEVICES)======================*/

@media screen and (max-width: 1024px) {
  .dataResults {
    top: 28rem;
    right: 10em;
  }
}
.searchIcon:hover{
 background-color: black !important;
}
@media screen and (max-width: 675px) {
  .search .search-inputs:hover > #searchInput {
    width: auto;
    padding: 0.3rem 6px;
    position: absolute;
    top: 4rem;
    left: 0rem;
  }
  .dataResults {
    top: 28rem;
    left: 1rem;
  }
}
