.cart-totals {
  width: 80%;
  height: 100px;
  background: var(--color-secondary);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  filter: drop-shadow(0px 24px 50px rgba(18, 18, 18, 0.18));
  border-radius: 12px;
}
.cart-totals-div {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--color-heading);
  cursor: pointer;
}
.cart-totals-div:hover {
  background: var(--color-white);
  color: var(--color-secondary);
  transition: var(--transition);
}
.cart-totals-div div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}
.cart-totals-overload {
  width: 80%;
  height: 100px;
  background: var(--color-secondary);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  filter: drop-shadow(0px 24px 50px rgba(18, 18, 18, 0.18));
}
.cart-totals-overload-div {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--color-heading);
  cursor: pointer;
}
.cart-totals-overload-div:nth-child(1),
.cart-totals-overload-div:nth-child(3) {
  border-radius: 12px 0px 0px 12px;
}
/*===================MEDIA QUERYIES (MEDIUM DEVICES)======================*/

@media screen and (max-width: 1024px) {
}

/*===================MEDIA QUERYIES (SMALL DEVICES)======================*/

@media screen and (max-width: 600px) {
  .cart-totals {
    height: 100%;
    width: 100%;
    padding: 2%;
  }
  .cart-totals-div div {
    gap: 5px;
  }
  .cart-totals-div > div > img {
    width: 17px;
    height: 20;
  }
  .cart-totals-div span {
    font-size: 16px;
    font-family: "Nunito Sans", sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
  }
}
