.payment {
  width: 100%;
}
.payment em {
  display: none;
}

.final-invoice-step {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  padding: 0 40px;
}
.payment-info-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}
.payment-info-details span,
.payment-info-details2 span {
  display: block;
}
.payment-info-details2 {
  margin-top: 4rem;
}
#moneyId {
  margin-bottom: 32px;
  display: block;
  font-size: 24px;
}
#finalInvoiceWalletImgContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
#payment-paragraph {
  display: none;
}
/*===================MEDIA QUERYIES (MEDIUM DEVICES)======================*/

@media screen and (max-width: 1024px) {
  #moneyId {
    display: block;
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
  .payment-info-details2 {
    margin-top: 6.5rem;
  }
}
/*===================MEDIA QUERYIES (SMALL DEVICES)======================*/

@media screen and (max-width: 600px) {
  .payment-info-details2 {
    margin-top: 8.5rem;
  }
  .final-invoice-step {
    padding: 0 24px;
  }
  .payment p {
    display: block;
    padding: 0 8rem 0 24px;
    margin-top: 19px;
    margin-bottom: 12px;
  }
  [dir="rtl"] .payment p {
    padding: 0 24px 0 8rem;
  }

  .payment-info-step {
    margin-top: 100px;
  }
  #moneyId {
    margin-bottom: 16px;
  }
}
