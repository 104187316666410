.admin-cart-totals {
  width: 80%;
  height: 100px;
  background: var(--color-secondary);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  filter: drop-shadow(0px 24px 50px rgba(18, 18, 18, 0.18));
}
.admin-cart-totals-div {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--color-heading);
  cursor: pointer;
}
.admin-cart-totals-div:hover {
  background: var(--color-white);
  color: var(--color-secondary);
  transition: var(--transition);
}
.admin-cart-totals-div div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.admin-cart-totals-overload {
  width: 80%;
  height: 100px;
  background: var(--color-secondary);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  filter: drop-shadow(0px 24px 50px rgba(18, 18, 18, 0.18));
  border-radius: 15px;
}
.admin-cart-totals-overload-div {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--color-heading);
  cursor: pointer;
}

/*===================MEDIA QUERYIES (SMALL DEVICES)======================*/

@media screen and (max-width: 600px) {
  .admin-cart-totals {
    flex-direction: column;
    height: 100%;
    padding: 4%;
  }
  .admin-cart-totals-div {
    margin-bottom: 30px;
    border-radius: 14px;
  }
}
