.admin-shipment-products {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.admin-shipment-products-cards {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 48px auto;
  height: 100%;
}
#category-span:hover {
  cursor: pointer;
  color: var(--color-primary);
}
.admin-cart-categories {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
}
.admin-shipment-product-card-h2 {
  position: relative;
}
/*===================MEDIA QUERYIES (MEDIUM DEVICES)======================*/

@media screen and (max-width: 1024px) {
  .admin-shipment-products {
    height: 100%;
  }
}

/*===================MEDIA QUERYIES (SMALL DEVICES)======================*/

@media screen and (max-width: 600px) {
}
