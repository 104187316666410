@use "../../scss/abstracts/" as *;
@use "sass:math";

.sidebar {
  @include flex(stretch, flex-start);
  flex-direction: column;
  color: $txtColor;
  width: $sideBarWidth;
  height: 100vh;
  background-color: black;
  position: fixed;
  top: 0;
  z-index: 100; 
  overflow-x: hidden;
  overflow-y: auto;
  text-align: justify;

  @include tablet {
    flex-direction: column;
    color: $txtColor;
    width: $sideBarWidth;
    height: 100vh;
    background-color: black;
    position: fixed;
    top: 0;
    z-index: 100;
    overflow-x: hidden;
    overflow-y: auto;
    text-align: justify;
  }
  [dir="rtl"] .sidebar {
    right: 0;
  }
  &__logo {
    @include flex();
    height: $sideBarLogoHeight;

    @include tablet {
      justify-content: space-between;
      height: math.div($sideBarLogoHeight, 1.8);
      padding: 0 $mobileSpacing;
      margin-bottom: $mobileSpacing;
    }

    img {
      --size: 200px;
      width: var(--size);
      height: var(--size);
    }

    .sidebar-close {
      cursor: pointer;
      display: none;

      i {
        font-size: 2rem;
      }

      @include tablet {
        display: block;
      }
    }
  }

  &__menu {
    @include flex(stretch, flex-start);
    flex-direction: column;
    flex-grow: 1;

    &__item {
      @include flex(center, flex-start);
      position: relative;
      margin-bottom: 2rem;
      transition: color 0.3s ease-in-out;

      @include tablet {
        padding-left: $mobileSpacing;
      }

      &:hover {
        color: $mainColor;

        @include tablet {
          color: $orange;
        }
      }

      &.active {
        font-weight: 700;
        color: #ffc107;;

        @include tablet {
          color: #ffc107;;
        }

        &::before {
          content: "";
          position: absolute;
          height: 100%;
          width: 5px;
          left: 0;
          top: 0;
          background-color: $mainColor;
          border-top-right-radius: 20px;
          border-bottom-right-radius: 20px;

          @include tablet {
            background-color: $orange;
          }
        }
      }

      &__icon {
        --size: 40px;
        width: var(--size);
        height: var(--size);
        margin-right: 3rem;
        @include flex(center, center);

        @include tablet {
          margin-right: 1rem;
        }

        i {
          font-size: 1.9rem;
        }
      }

      &:last-child {
        margin-top: auto;
        cursor: pointer;
      }
    }
  }
}
.dropdown-menu.show a {
  white-space: break-spaces;
}
.sidebar__menu__item__txt button{
  background: none;
  border: none;
  color: $txtColor;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 18px;

}

.sidebar__menu__item__icon i {
  font-size: 15px;
}
.sidebar__menu{
  margin-left: 20px;
}