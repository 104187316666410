.final-invoices-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--color-white);
  border-radius: 24px;
  width: 100%;
  height: 100%;
  gap: 32px;
  padding: 56px;
}
@media screen and (max-width: 576px) {
  .final-invoices-container {
    padding: 0;
    gap: 24px;
    background: none;
  }
}
