.country-langs-import form {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 30px;
    justify-content: center;
    align-items: center;
  }
  .state-langs{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 30px;
  
  }
  .country-langs-import .mrgin{
    margin-top: 30px;
  }
  .country-langs-import button{
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
  .padding{
    padding: 10px 0;
  }
  .import-states-agent{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
    gap: 20px;
    justify-content: center;
    align-items: center;
  }
  .check-import-state{
    margin-top: 10px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    justify-content: center;
    align-items: center;
  }