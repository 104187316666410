.lazy-loader {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
}
.lazy-loader div {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
.lazy-loader img {
  margin: 20px auto;
  width: 15%;
  height: 20%;
}
