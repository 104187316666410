.dropbtn {
  background-color: transparent;
  color: var(--color-secondary);
  padding: 16px;
  font-size: 24px;
  border: none;
  transform: rotate(90deg);
  position: absolute;
  top: -7rem;
  left: 2rem;
}

.cart-card-dropdown {
  position: absolute;
  margin-top: -6em;
  margin-left: -36px;
  display: inline-block;
}

.cart-card-dropdown-content {
  display: none;
  position: absolute;
  background-color: var(--color-white);
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.cart-card-dropdown-content a {
  color: var(--color-secondary);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

.cart-card-dropdown-content a:hover {
  background-color: #ddd;
}

.cart-card-dropdown:hover .cart-card-dropdown-content {
  display: block;
  position: relative;
  top: -2rem;
  left: 3rem;
}

.cart-card-dropdown:hover .dropbtn {
  background-color: transparent;
}

.cart-card {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  padding: 32px 32px 32px 0;
  background: var(--color-white);
  border: 1px solid var(--color-border);
  border-radius: 24px;
  box-shadow: -16px 13px 4px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 9px 0px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -16px 13px 4px 0px rgba(0, 0, 0, 0.75);
  width: 100%;
}
.cart-card:hover {
  box-shadow: -16px 13px 4px 0px rgb(184, 145, 28);
  -webkit-box-shadow: -10px 9px 0px 0px rgb(184, 145, 28);
  -moz-box-shadow: -16px 13px 4px 0px rgb(184, 145, 28);
  transition: var(--transition);
}
.cart-card-section2 {
  grid-area: 1 / 1 / 5 / 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
}
.cart-card-section2 img {
  width: 10rem;
  height: 10rem;
}
.cart-card-section1 {
  grid-area: 1 / 2 / 5 / 8;
}
.cart-card .cart-card-section1 {
  width: 100%;
}
.cart-card-h1 {
  padding: 0 1rem;
}
.cart-card-h1 .cart-card-title {
  grid-area: 1 / 1 / 3 / 5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.cart-card-h1 .cart-card-title-totals {
  padding: 1rem;
}
.cart-card-h1 .cart-card-title-totals span {
  display: block;
}
.cart-card-h1 .cart-card-details {
  grid-area: 3 / 1 / 6 / 5;
  display: flex;
  width: 100%;
}
.cart-card-h1 .cart-card-details .info-data {
  width: 70%;
}
.cart-card-h1 .cart-card-details .info-data {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.cart-card-h1 .cart-card-details .info-data .col {
  color: var(--color-paragraph);
}

.cart-card-h1 .cart-card-details .cart-card-qty {
  width: 30%;
}

.cart-card-h1 .cart-card-details .cart-card-qty input {
  width: 55px;
  text-align: center;
}
.cart-card-h1 .cart-card-details .cart-card-qty .btn-group {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 4px;
  gap: 10px;
  height: 70px;
  border: 1px solid var(--color-primary);
  border-radius: 8px;
}
.cart-card-h1 .cart-card-details .cart-card-qty .btn-group button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 6px;
  color: var(--color-secondary);
  background: var(--color-primary);
  border-radius: 50%;
  margin: 0 !important;
}
.cart-card-h1 .cart-card-details .cart-card-qty .btn-group button:hover {
  color: var(--color-primary);
  background: var(--color-secondary);
  transition: var(--transition);
}

.card-note textarea {
  box-sizing: border-box;
  width: 100%;
  height: 163px;
  background: #fafafa;
  border: 1px solid #868686;
  border-radius: 24px;
  padding: 4%;
  margin-top: 18px;
}
/*===================MEDIA QUERYIES (SMALL DEVICES)======================*/

@media screen and (max-width: 600px) {
  .cart-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px 7px 0px 8px;
    gap: 16px;
    width: 342px;
    background: #ffffff;
    border: 1px solid #4b4b4b;
    border-radius: 12px;
    position: relative;
  }
  .cart-card-section2 {
    position: absolute;
  }
  .cart-card-section2 img {
    height: 100%;
    width: 100%;
  }
  .cart-card-section1 {
    flex-direction: column;
  }
  .cart-card-title {
    flex-direction: column;
  }
  .cart-card-h1 {
    padding: 0;
  }
  .cart-card-h1 .cart-card-title {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: end;
  }
  .cart-card-h1 .cart-card-title .cart-card-title-btn button {
    height: 100%;
  }
  .cart-card-details {
    flex-direction: column;
  }
  .cart-card-h1 .cart-card-details .cart-card-qty {
    width: 100%;
    padding: 0 !important;
  }
  .cart-card-h1 .cart-card-details .cart-card-qty .btn-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 12px;
    width: 153px;
    height: 48px;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
  }
  .cart-card-h1 .cart-card-details .cart-card-qty p {
    font-family: "Nunito Sans";
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    color: #121212;
  }
  .cart-card-h1 .cart-card-details .info-data {
    display: flex;
    width: 100%;
    grid-gap: 0;
    gap: 0;
    height: 100%;
  }
  .cart-summary .cart-btn {
    width: 90%;
  }
  .cart-card-dropdown {
    position: absolute;
    margin-top: -3em;
    left: 0rem;
    display: inline-block;
  }
  .cart-card-section2 img {
    width: 70px;
    height: 66px;
  }
  .cart-card-title-info {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 31px;
    position: relative;
    left: 1.5rem;
    height: 4.9rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .cart-card-title-info h5 {
    width: 232px;
    font-family: "Delius Unicase";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    margin: 0;
    color: #121212;
    text-align: start;
  }
  .cart-card-title-info p {
    display: none;
  }
  .dropbtn {
    top: -2rem;
    left: 21px;
  }
  .info-data {
    font-family: "Nunito Sans", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0em;
  }
  .cart-card-h1 .cart-card-details {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
    position: relative;
    top: 12px;
  }
  .cart-card-h1 .cart-card-details .info-data .row > .col:nth-child(1) {
    font-family: "Nunito Sans";
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    color: #121212;
  }
  .info-data .row {
    margin: 0 !important;
  }
  .cart-card-title-totals {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 12px 24px;
    gap: 10px;
    width: 153px;
    border-radius: 12px;
    position: absolute;
    top: 13rem;
    right: 7px;
    z-index: 99;
    font-family: "Nunito Sans";
    font-size: 10px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
  }
  .cart-card-title-totals span {
    font-family: "Nunito Sans";
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    color: #121212;
  }
  .cart-card-title-totals > span:nth-child(2) {
    border: 1px solid #d9d9d9;
    padding: 8px 12px;
    width: 153px;
    height: 48px;
    border-radius: 8px;
    position: relative;
    display: flex;
    top: 6px;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  .card-note textarea {
    font-family: "Nunito Sans", sans-serif;
    font-size: 14px;
  }
}
