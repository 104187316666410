.shipment-suggestion {
  width: 100%;
  height: 100%;
  padding: 4%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.suggestion-title h4 {
  margin-bottom: 24px;
  text-align: center;
}

.suggestion-title p {
  color: var(--color-paragraph);
}
.suggestion-files-container {
  width: 90%;
  padding: 4%;
  margin-bottom: 54px;
  background: var(--color-white);
  border: 3px dashed var(--color-primary);
  box-shadow: -4px -4px 8px rgba(18, 18, 18, 0.04);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.suggestion-files-container .suggestion-files {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.suggestion-files-container .suggestion-files button {
  width: 100%;
  background: transparent;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 24px;
  font-family: "Nunito Sans";
  padding: 16px;
}
.suggestion-file {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background: rgba(217, 217, 217, 0.12);
  border: 1px solid rgba(134, 134, 134, 0.32);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.suggestion-file:hover {
  border: 1px solid black;
  transition: var(--transition);
}
.suggestion-file button:hover {
  transition: var(--transition);
}
.btns {
  margin-top: 56px;
  display: flex;
  align-items: center;
  gap: 32px;
}

.btns button {
  width: 211px;
}
.btns .edit-suggestion {
  background: linear-gradient(
    90deg,
    rgba(255, 217, 96, 0.12) -3.88%,
    rgba(255, 209, 63, 0.12) 103.88%
  );
}
/*===================MEDIA QUERYIES (MEDIUM DEVICES)======================*/

@media screen and (max-width: 1024px) {
  .shipment-suggestion {
    gap: 24px;
  }
  .suggestion-files-container {
    margin-bottom: 0;
    width: 100%;
  }
}

/*===================MEDIA QUERYIES (SMALL DEVICES)======================*/

@media screen and (max-width: 600px) {
  .suggestion-file {
    width: 314px;
    height: 56px;
    border: 1px solid rgba(112, 112, 112, 0.32);
    border-radius: 12px;
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
  }
  .suggestion-file button svg {
    width: 24px;
    height: 24px;
  }
  .suggestion-files-container {
    margin-bottom: 0;
  }
  .suggestion-files-container img {
    /* width: 150px; */
    display: none;
  }
  .shipment-suggestion {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
  }
  .suggestion-title h4 {
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0em;
  }
  .suggestion-title p {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
  }
  .btns {
    width: 100%;
    gap: 12px;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
  }
  .btns button {
    width: 13rem;
    height: 56px;
    left: 123px;
    top: 413px;
    background: linear-gradient(90deg, #ffd960 -3.88%, #ffd13f 103.88%);
    border-radius: 16px;
    margin: 0;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0em;
  }
  .suggestion-files-container .suggestion-files {
    width: auto;
  }
  .suggestion-files-container .suggestion-files button {
    font-family: "Nunito Sans";
    font-size: 14px;
  }
}
