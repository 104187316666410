.shipment-order-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 0px 0px;
  position: relative;
  background: #ffffff;
  box-shadow: 0px 12px 24px rgba(18, 18, 18, 0.08);
  border-radius: 12px;
  width: 100%;
}
.shipment-order-card-close {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  position: absolute;
  top: 0;
}
.close-icon {
  height: 30px;
  width: 30px;
  margin: 20px;
  background: var(--color-primary);
  border-radius: 50%;
}
.close-icon:hover {
  background: var(--color-warning);
  border-radius: 50%;
  cursor: pointer;
}
.order-card-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 45px 5% 0 5%;
}
.order-card-header #order-card-header-img {
  width: 80px;
}
.order-card-header-info {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.order-card-header-info > div:nth-child(1) {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.order-card-header-info > div:nth-child(1) div span {
  font-size: 32px;
}
.order-card-header-info > div:nth-child(1) p {
  font-size: 17px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
}
.order-status-label {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 12px 16px;
  gap: 4px;
  width: fit-content;
  height: 46px;
  background: linear-gradient(
    90deg,
    rgba(255, 217, 96, 0.4) -3.88%,
    rgba(255, 209, 63, 0.4) 103.88%
  );
  border-radius: 12px;
}
.order-status p {
  font-family: "Nunito Sans";
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0em;
  color: black;
  margin-bottom: 0;
  font-weight: 600 !important;
}

/* =================Orders Suggesion====== */

.suggesion-tag {
  background: rgba(101, 112, 116, 0.2);
}
.finished-tag {
  background: rgba(79, 191, 38, 0.2);
  color: green;
}
/* =================End Orders Suggesion====== */

.order-card-header-info button {
  background: transparent;
  border: none;
  color: var(--color-primary);
  font-size: 24px;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: 0em;
}
.order-card-header-info button:hover {
  background: var(--color-secondary);
  border: none;
  color: var(--color-primary);
  font-size: 24px;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: 0em;
}
.order-card-header-info p span {
  color: var(--color-paragraph);
}
.order-card-body {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 5%;
}
.order-card-body h3 {
  margin-bottom: 30px;
  font-size: 1.9rem;
  font-size: 20px;
  font-weight: 600;
  line-height: 31px;
  letter-spacing: 0em;
}
.order-card-body-items {
  width: 100%;
  height: 100%;
  border-radius: 22.5px;
}
.order-card-body-items-img-container {
  border: 1px solid var(--color-primary);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.order-card-body-items img {
  border-radius: 8px;
  width: 100%;
  border: 1px solid var(--color-primary);
}
.order-card-body-items .carousel-container ul {
  gap: 16px;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  margin-bottom: 30px;
}
.order-card-body .order-card-body-info {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px auto;
}
.order-card-body .order-card-body-info span:first-of-type {
  font-weight: bold;
  color: black;
}
.order-card-footer {
  width: 100%;
  height: 5rem;
  background: #f7f7f7;
  border-radius: 0px 0px 12px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.order-card-footer .order-card-footer-btns {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.order-card-footer .order-card-footer-btns span {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400 !important;
  gap: 10px;
  font-family: "Nunito Sans";
}

.order-card-footer .order-card-footer-btns button {
  font-size: 18px;
  border-left: 1px solid;
}
.order-card-footer .order-card-footer-btns a,
.order-card-footer .order-card-footer-btns button {
  width: 100%;
  margin: 20px;
  background: transparent;
  border: none;
  color: var(--color-secondary);
  display: flex;
  align-items: center;
  justify-content: center;
}

.order-card-footer .order-card-footer-btns button:hover {
  color: var(--color-secondary);
  transition: var(--transition);
}
.order-card-footer .order-card-footer-btns button:nth-child(2):before {
  content: "";
  position: relative;
  height: 64px;
  left: 0px;
  width: 1px;
  background: var(--color-paragraph);
}
#status {
  margin-top: 40px;
  margin-bottom: 32px;
}
#breaker {
  width: 50%;
  margin-top: 9px;
  margin-bottom: 18px;
}
#breaker2 {
  width: 50%;
  margin-top: 36px;
  margin-bottom: 36px;
}
/*===================MEDIA QUERYIES (SMALL DEVICES)======================*/

@media screen and (max-width: 600px) {
  #status {
    text-align: center;
    font-size: 1rem;
  }
  #breaker2 {
    display: none;
  }
  .suggesion-tag svg {
    display: none;
  }
  .order-card-header {
    gap: 8px;
    padding-top: 12px;
  }
  .order-card-header-info {
    text-align: center;
  }
  /* .order-card-header-info > div:nth-child(1) h6 {
    font-size: 24px;
    font-weight: 600;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: right;
    margin-bottom: 0;
  } */
  .order-card-header-info > div:nth-child(1) > span:nth-child(1) {
    display: none;
  }
  .order-card-header-info > div:nth-child(1) > span:nth-child(2) {
    font-size: 18px;
  }
  .order-card-header-info > div:nth-child(1) p {
    font-size: 20px;
    font-weight: 500;
    line-height: 31px;
    letter-spacing: 0em;
  }
  .order-card-body .order-card-body-info {
    margin: 20px auto;
  }
  .order-card-body .order-card-body-info p {
    margin: 0;
    display: flex;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: right;
    gap: 4px;
  }
  .order-card-footer {
    border-radius: 0px 0px 12px 12px;
    height: 48px;
  }
  .order-card-footer .order-card-footer-btns a,
  .order-card-footer .order-card-footer-btns button {
    margin: 0;
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
  }
  .shipment-order-card {
    width: 100%;
  }
  .order-card-footer .order-card-footer-btns button::before {
    left: -1rem;
  }

  .order-card-footer .order-card-footer-btns span {
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
  }
  .order-card-footer .order-card-footer-btns span img,
  .order-card-footer .order-card-footer-btns span svg {
    width: 17px;
    height: 18px;
  }
  .order-card-footer .order-card-footer-btns button:nth-child(2):before {
    height: 32px;
    left: -14px;
  }
  .order-card-body h3 {
    display: none;
  }

  .suggesion-tag svg {
    display: none;
  }
  .order-card-header #order-card-header-img {
    width: 56px;
  }
  .order-status-label {
    padding: 8px;
    height: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  .order-status-label svg {
    display: none;
  }
  .order-status p {
    font-size: 12px;
  }
  .order-card-body-items .carousel-container ul {
    gap: 10px;
  }
  .order-card-body-items .carousel-container ul li {
    width: 72px !important;
  }

  .order-card-header-info > div:nth-child(1) span {
    margin: 0;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
  }
  .order-card-header-info > div:nth-child(1) img {
    width: 17px;
    height: 18px;
  }
}
