.shipment-orders {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5%;
}
.shipment-orders-title {
  width: 100%;
}
.shipment-orders .shipment-orders-title h6 {
  margin-bottom: 60px;
  width: 100%;
  font-size: 25px;
  font-weight: 700;
  line-height: 39px;
  letter-spacing: 0em;
}
.orders-container {
  width: 100%;
  background: var(--color-white);
  border-radius: 24px;
  padding: 24px 24px 0 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
}
.orders-container .addOrder {
  margin-top: 100px;
}
/*===================MEDIA QUERYIES (SMALL DEVICES)======================*/

@media screen and (max-width: 600px) {
  .shipment-orders {
    padding: 0;
  }
  .orders-container .addOrder {
    margin: 0;
    margin-bottom: 56px;
    width: auto !important;
  }
  .shipment-orders .shipment-orders-title h2 {
    margin-bottom: 24px;
  }
  .shipment-orders .shipment-orders-title h2 {
    font-size: 1.9rem;
    margin-top: 24px;
  }
  .shipment-orders .shipment-orders-title h6 {
    margin-bottom: 0;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0em;
    font-style: normal;
    font-size: 25px;
    line-height: 32px;
    text-transform: capitalize;
    text-align: center;
    margin-top: 56px;
    margin-bottom: 24px;
  }
}
