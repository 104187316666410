.shipment-docs {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 5%;
}
.docs-title h3 {
  margin-bottom: 40px;
}
.docs-container {
  width: 90%;
  height: 100%;
  background: var(--color-white);
  border: 3px dashed var(--color-primary-variant);
  box-shadow: -4px -4px 8px rgba(18, 18, 18, 0.04);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.docs-container .docs-container-files .docs-container-file {
  width: 30em;
  height: 72px;
  background: rgba(217, 217, 217, 0.12);
  border: 1px solid rgba(134, 134, 134, 0.32);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2%;
  margin-bottom: 24px;
  cursor: pointer;
}
.docs-container .docs-container-files .docs-container-file:hover {
  border: 2px solid var(--color-primary);
  transition: var(--transition);
}
.docs-container .docs-container-files .docs-container-file div img {
  margin-right: 10px;
}
.docs-container .docs-container-btn {
  width: 100%;
}

.docs-container .docs-container-btn a:hover {
  background: var(--color-secondary);
  color: var(--color-primary);
  transition: var(--transition);
}
/*===================MEDIA QUERYIES (SMALL DEVICES)======================*/

@media screen and (max-width: 600px) {
  .docs-container .docs-container-files .docs-container-file {
    width: 100%;
  }
}
