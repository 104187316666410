@use "./abstracts/" as *;

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=El+Messiri:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=El+Messiri:wght@400;500;600;700&family=Tajawal:wght@200;300;400;500;700;800;900&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  overflow-x: hidden;
}

body {
  font-family: $fontFamily;
  color: $txtColor;
  font-size: $fontSizeNormal;
  line-height: 1.5;
  background-color: $bodyBg;
  overflow-x: hidden;
}

a {
  text-decoration: none;
  color: unset;
}

ul,
li {
  list-style-type: none;
}

.mb {
  margin-bottom: $spacing;

  @include tablet {
    margin-bottom: $mobileSpacing;
  }
}

.title {
  font-size: $fontSizeMedium;
  font-weight: 700;
}

.txt-success {
  color: $green;
}

.txt-danger {
  color: $red;
}
.dashboard {
  display: flex;
  font-size: 13px;
  font-family: "Work Sans", sans-serif;
}
