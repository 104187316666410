[class*="col-"] {
  padding: 0;
}
.admin-bg {
  background: url("../../assets/img/Pages/Agent/slider.png") no-repeat center
    center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  min-width: 1024px;
  width: 100%;
  height: 100vh;
}
@media screen and (max-width: 1024px) {
  .admin-bg {
    left: 50%;
  }
}
.SH {
  background: rgba(0, 0, 0, 0.7);
  height: 100vh;
  padding: 10%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}
.SH img:nth-child(2) {
  position: absolute;
  top: -15%;
  right: 0;
}
.admin-login-FH {
  background: rgb(255, 255, 255, 0.6);
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.admin-login-FH-form {
  background: white;
  width: 60%;
  padding: 4%;
  border-radius: 15px;
  -webkit-box-shadow: -16px 13px 4px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -16px 13px 4px 0px rgba(0, 0, 0, 0.75);
  box-shadow: -16px 13px 4px 0px rgba(0, 0, 0, 0.75);
}
.admin-login-FH-form h4 {
  margin-bottom: 25px;
}
.admin-login-FH-form .admin-formComponents {
  text-align: left;
}
.admin-login-FH-form .admin-formComponents .email-icon,
.admin-login-FH-form .admin-formComponents .password-eye {
  position: relative;
  left: 90%;
  top: -2em;
}
[dir="rtl"] .admin-login-FH-form .admin-formComponents .email-icon,
.admin-login-FH-form .admin-formComponents .password-eye {
  right: 4%;
  top: -2rem;
}
.admin-login-FH-form .loginBtn {
  margin-top: 5%;
  width: 100%;
  background: #e6b43f;
  border: none;
  color: black;
  display: flex;
  justify-content: center;
}
.admin-login-FH-form a {
  text-decoration: none;
}
.admin-login-FH-form .loginBtn:hover {
  color: #e6b43f;
  background: black;
  transition: all 0.8s ease-in-out;
  width: 100%;
}
@media screen and (max-width: 575px) {
  .admin-loginform .email-icon {
    position: relative;
    left: 0;
  }
  /* Specific to this particular image */
  .admin-login-FH {
    width: 40%;
  }
  .admin-login-FH-form {
    width: 90%;
    padding: 10px;
    margin: 10px;
    margin: 20px;
    font-size: 13px;
  }
  .admin-login-FH-form .admin-formComponents .password-eye,
  .admin-login-FH-form .admin-formComponents .email-icon {
    position: absolute;
    right: 37%;
    margin-top: -25px;
    cursor: pointer;
    color: #707070;
  }
  .SH {
    width: 100%;
  }
}
.admin-formComponents label{
  font-weight: 500;
  font-size: 16px;
}