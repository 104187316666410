.updateBtn {
  margin: 0;
}
.P-Register-FH {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 5%;
  background: rgba(255, 255, 255, 0.8) url("../../../assets/slider_9.png") fixed
    center no-repeat;
}
.P-Register-FH-form {
  background-color: var(--color-secondary);
  padding: 3%;
  border-radius: 10px;
  color: aliceblue;
  width: 50%;
}
.P-Register-FH-form .icon2 {
  position: absolute;
  left: 66em;
  top: 60.6%;
}
.P-Register-FH-form .icon1 {
  position: absolute;
}
@media screen and (max-width: 575px) {
  .P-Register-FH-form {
    width: 100%;
  }
  .P-Register-FH-form form {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
  .P-Register-FH-form .formGroup1 {
    margin: 1em;
    display: flex;
    flex-direction: column;
  }
}
