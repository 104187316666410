.create-partner {
  display: flex;
  flex-direction: row;
  padding: 20px;
}
.partner-desc-form,
.partner-titles-form {
  width: 48%;
  margin: 10px;
}
