.admin-cart-card-dropbtn {
  background-color: transparent;
  color: var(--color-secondary);
  padding: 16px;
  font-size: 24px;
  border: none;
  transform: rotate(90deg);
}

.admin-cart-card-dropdown {
  position: absolute;
  margin-top: -6em;
  margin-left: -36px;
  display: inline-block;
}

.admin-cart-card-dropdown-content {
  display: none;
  position: absolute;
  background-color: var(--color-white);
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.admin-cart-card-dropdown-content a {
  color: var(--color-secondary);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

.admin-cart-card-dropdown-content a:hover {
  background-color: #ddd;
}

.admin-cart-card-dropdown:hover .admin-cart-card-dropdown-content {
  display: block;
}

.admin-cart-card-dropdown:hover .dropbtn {
  background-color: transparent;
}

.admin-cart-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 32px;
  width: 100%;
  height: 100%;
  background: var(--color-white);
  border: 1px solid var(--color-border);
  border-radius: 24px;
  box-shadow: -16px 13px 4px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 9px 0px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -16px 13px 4px 0px rgba(0, 0, 0, 0.75);
  margin-bottom: 50px;
}
.admin-cart-card:hover {
  box-shadow: -16px 13px 4px 0px rgb(184, 145, 28);
  -webkit-box-shadow: -10px 9px 0px 0px rgb(184, 145, 28);
  -moz-box-shadow: -16px 13px 4px 0px rgb(184, 145, 28);
  transition: var(--transition);
}
.admin-cart-card-h2 {
  grid-area: 1 / 5 / 6 / 7;
  width: 30%;
}
.admin-cart-card-h2 img {
  width: 100%;
  height: 100%;
}
.admin-cart-card-h1 {
  grid-area: 1 / 1 / 6 / 5;
  width: 70%;
}
.admin-cart-card .admin-cart-card-section1 {
  display: flex;
  width: 100%;
}
.admin-cart-card-h1 .admin-cart-card-title {
  grid-area: 1 / 1 / 3 / 5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.admin-cart-card-h1 .admin-cart-card-title-totals {
  padding: 1rem;
}
.admin-cart-card-h1 .admin-cart-card-title-totals span {
  display: block;
}
.admin-cart-card-h1 .admin-cart-card-details {
  grid-area: 3 / 1 / 6 / 5;
}
.admin-cart-card-h1 .admin-cart-card-details .info-data {
  width: 70%;
}
.admin-cart-card-h1 .admin-cart-card-details .admin-info-data {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.admin-cart-card-h1 .admin-cart-card-details .admin-info-data .col {
  color: var(--color-paragraph);
}

.admin-cart-card-h1 .admin-cart-card-details .admin-cart-card-qty {
  width: 30%;
}
.admin-cart-card-h1 .admin-cart-card-details .admin-cart-card-qty input {
  width: 40px;
  text-align: center;
}
.admin-cart-card-h1 .admin-cart-card-details .admin-cart-card-qty .btn-group {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 4px;
  gap: 10px;
  width: 176px;
  height: 70px;
  border: 1px solid var(--color-primary);
  border-radius: 8px;
}
.admin-cart-card-h1 .admin-cart-card-details .admin-cart-card-qty .red {
  border: 1px solid red !important;
}
.admin-cart-card-h1
  .admin-cart-card-details
  .admin-cart-card-qty
  .btn-group
  button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 6px;
  color: var(--color-secondary);
  background: var(--color-primary);
  border-radius: 50%;
}
.admin-cart-card-h1
  .admin-cart-card-details
  .admin-cart-card-qty
  .btn-group
  button:hover {
  color: var(--color-primary);
  background: var(--color-secondary);
  transition: var(--transition);
}

.admin-card-note textarea {
  box-sizing: border-box;
  width: 100%;
  height: 163px;
  background: #fafafa;
  border: 1px solid #868686;
  border-radius: 24px;
  padding: 4%;
  margin-top: 18px;
}
/*===================MEDIA QUERYIES (SMALL DEVICES)======================*/

@media screen and (max-width: 600px) {
  .admin-cart-card {
    display: flex;
    flex-direction: column;
  }
  .admin-cart-card-h2 img {
    height: 100%;
    width: 100%;
  }
  .admin-cart-card-h1 .admin-cart-card-title .admin-cart-card-title-btn button {
    height: 100%;
  }
  .admin-cart-card-h1 .admin-cart-card-details .admin-cart-card-qty .btn-group {
    width: 100%;
  }
  .admin-cart-card-h1 .admin-cart-card-details .admin-cart-card-qty {
    width: 50%;
  }
}
