.country-categories-products {
  display: flex;
  width: 100%;
}
.country-categories-products input {
  cursor: pointer;
}
.country-display-products-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: baseline;
  width: 100%;
  height: 50%;
  overflow-y: scroll;
}
.create-country {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  align-items: center;
}
.create-country button {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.cat{
  display: grid;
  grid-template-columns: repeat(2,auto);
}