.create-about-us form {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}
.create-about-us .btn {
  display: flex;
}
.crud-about {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  gap: 4rem;
}
.btns{
  display: flex;
  height: fit-content;
  width: 100%;
  align-items: center;
}
.btns button{
  display: flex;
  justify-content: center;
  font-size: 15px;
}
.card-item-about {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  text-align: center;
  justify-content: center;
  align-items: center;
  gap: 5px;

}
.card-item-baner {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  text-align: center;
  justify-content: center;
  align-items: center;
  gap: 5px;
}