.shipment-pop-up {
  width: 671px;
  height: 560px;
  background: var(--color-white);
  box-shadow: -4px -4px 8px rgba(18, 18, 18, 0.04);
  border-radius: 12px 12px 0px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
