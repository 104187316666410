header {
  font-family: Grillages;
}
.navbar-light .navbar-nav .nav-link {
  padding-left: 0;
  padding-right: 0;
}
.navbar-nav {
  gap: 24px;
  font-weight: 600;
  letter-spacing: 2px;
}
.header-form {
  height: 5.1rem;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}
.header-form .person-info {
  height: 100%;
}
.auth-dropdown {
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
}
.auth-dropdown:hover {
  cursor: pointer;
  background: var(--color-heading);
  transition: 0.4s;
}
#userIcon {
  color: var(--color-white);
  width: 32px;
}
.auth-dropdown:hover #userIcon {
  color: #121212;
}
.auth-dropdown-content {
  position: absolute;
  left: -8rem;
  top: 5.1rem;
  background: #ffffff;
  box-shadow: 0px 12px 24px rgba(230, 230, 230, 0.7);
  border-radius: 12px;
  z-index: 11;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  display: none;
}
[dir="rtl"] .auth-dropdown-content {
  left: -5rem;
  width: 200px;
}
.auth-dropdown-content ul {
  position: relative;
  margin: 0;
}
.auth-dropdown-content ul li {
  text-align: center;
  cursor: pointer;
  padding: 1rem 2rem;
  width: 100%;
  border-radius: 12px;
  text-transform: uppercase;
}
.auth-dropdown-content ul li:hover {
  background: var(--color-primary);
  border-radius: 12px;
}
.auth-dropdown-content ul li a:hover {
  color: #121212;
}
.auth-dropdown:hover .auth-dropdown-content {
  display: block;
  position: absolute;
}

/* ===================================================== */
.navbar-brand {
  padding: 0;
}

.nav-dropdown {
  position: relative;
  display: inline-block;
  color: #f9f9f9;
}
.nav-dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
}
.nav-dropdown:hover .nav-dropdown-content {
  display: block;
}
#nav-dropdown-link {
  color: #121212;
}
#nav-dropdown-link:hover {
  color: var(--color-primary);
  transition: var(--transition);
}
.user-nav {
  color: var(--color-paragraph);
  display: inline-block;
  padding: 10px 3em;
}
.nav-info-LR,
.nav-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
  padding: 0 20px;
}
.nav-info-LR {
  justify-content: flex-start;
  height: 100%;
}
.nav-info-LR button {
  border: none;
  background: transparent;
  margin: 10px;
}
.nav-info-LR svg {
  width: 30px;
  height: 30px;
  color: var(--color-paragraph);
}
.nav-info-LR a {
  text-decoration: none;
  color: var(--color-paragraph);
}
.nav-info .nav-info-one,
.nav-info .nav-info-three {
  padding: 2%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
}
.nav-info-two {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.nav-info-two button {
  margin: 0px 10px;
  border: none;
  background: transparent;
}
.nav-info-two a {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: var(--color-paragraph);
  font-size: 15px;
}
.nav-info .nav-info-one svg {
  height: 100%;
  width: 100%;
  padding: 0 5px;
}
.nav-info .nav-info-one button {
  width: 100%;
  margin: 0px 10px;
  border: none;
  background: transparent;
}
.nav-info .nav-info-one a,
.nav-info .nav-info-two a {
  text-decoration: none;
  color: var(--color-paragraph);
}
.nav-info .nav-info-one a:hover,
.nav-info .nav-info-two a:hover {
  color: var(--color-secondary);
  transition: all 0.3s ease-in-out;
}
.nav-info-desc {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin: 0;
}
.nav-info-desc p {
  margin: 0;
}
.navbar-light .navbar-toggler {
  background: var(--color-heading);
  margin: 1rem 0;
}

.navbar-light .navbar-nav .nav-link {
  color: var(--color-heading);
  font-size: 1rem;
}
.navbar-light .navbar-nav .nav-link:hover {
  color: var(--color-primary);
}
.header-cart {
  position: relative;
  width: 50px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
#headerCart {
  color: var(--color-primary);
  width: 30px;
  height: 30px;
  position: relative;
}
.header-cart:hover {
  background: var(--color-primary);
  transition: 0.4s;
}
.header-cart:hover #headerCart {
  color: var(--color-secondary);
}
.cart-quantity {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 2px;
  left: 1rem;
  color: var(--color-primary);
}
.nav-info > div:nth-child(1) {
  padding: 10px;
}
.nav-info div:nth-of-type(1):hover .notifications {
  display: block;
  position: absolute;
}
.nav-info div:nth-of-type(1):hover .notifications:hover {
  display: block;
  position: absolute;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.navbar-container {
  height: 5.1rem;
}
.notification-icon {
  color: #fff;
  cursor: pointer;
  height: 100%;
  width: 50px;

  display: flex;
  align-items: center;
  justify-content: center;
}
.notification-icon:hover {
  background: var(--color-heading);
}
.notification-icon:hover svg {
  color: #121212;
}
.notification-icon svg {
  width: 30px;
  height: 30px;
}

/*===================MEDIA QUERYIES (MEDIUM DEVICES)======================*/

@media screen and (max-width: 1024px) {
  .user-nav {
    padding: 10px 1em;
  }
  .nav-info-two {
    display: flex;
    justify-content: space-around;
    height: 100%;
  }
  .navbar-container {
    height: 100%;
  }
}

/*===================MEDIA QUERYIES (SMALL DEVICES)======================*/

@media screen and (max-width: 600px) {
  .navbar-container {
    height: 100%;
  }
  #headerCart {
    top: -3px;
  }
  .header-form {
  }
}
