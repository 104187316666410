@import url('https://fonts.googleapis.com/css?family=Anton&display=swap" rel="stylesheet');
.scroll {
  cursor: pointer;
  width: 70px;
  height: 70px;
  position: fixed;
  bottom: 40px;
  right: -80px;
  border-radius: 100%;
  background-image: radial-gradient(
    circle farthest-corner at 10% 20%,
    rgba(90, 92, 106, 1) 0%,
    var(--color-secondary)
  );
  color: var(--color-white);
  font-size: 44px;
  font-weight: bold;
  text-align: center;
  box-shadow: 0 0 5px 0px #888;
  transition: 300ms;
  z-index: 99999;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  margin: 0;
}
.scroll2 {
  cursor: pointer;
  width: 70px;
  height: 70px;
  position: fixed;
  top: 80%;
  bottom: 20px;
  right: 30px;
  border-radius: 100%;
  border: 1px solid gold;
  background-image: radial-gradient(
    circle farthest-corner at 10% 20%,
    rgba(90, 92, 106, 1) 0%,
    var(--color-secondary)
  );
  color: var(--color-white);
  font-size: 44px;
  font-weight: bold;
  text-align: center;
  box-shadow: 0 0 5px 0px #888;
  transition: 300ms;
  z-index: 99999;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  margin: 0;
}
.scroll3 {
  cursor: pointer;
  width: 70px;
  height: 70px;
  position: fixed;
  top: 25%;
  bottom: 20px;
  right: 30px;
  border-radius: 100%;
  border: 1px solid var(--color-primary);
  background-image: radial-gradient(
    circle farthest-corner at 10% 20%,
    rgba(90, 92, 106, 1) 0%,
    var(--color-secondary)
  );
  color: var(--color-white);
  font-size: 44px;
  font-weight: bold;
  text-align: center;
  box-shadow: 0 0 5px 0px #888;
  transition: 300ms;
  z-index: 99999;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  margin: 0;
}

.scroll4 {
  cursor: pointer;
  width: 70px;
  height: 70px;
  position: fixed;
  top: 77%;
  bottom: 20px;
  right: 30px;
  border-radius: 100%;
  border: 1px solid var(--color-primary);
  background-image: radial-gradient(
    circle farthest-corner at 10% 20%,
    rgba(90, 92, 106, 1) 0%,
    var(--color-secondary)
  );
  color: var(--color-white);
  font-size: 44px;
  font-weight: bold;
  text-align: center;
  box-shadow: 0 0 5px 0px #888;
  transition: 300ms;
  z-index: 99999;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  margin: 0;
}

.scroll2 img {
  margin: 0px auto;
}
.scroll svg {
  margin: 20px auto;
}
.scroll3 svg {
  margin: 0px auto;
}
.scroll2 svg {
  margin: 20px auto;
}
.scroll i {
  text-shadow: 0 0 2px var(--color-white);
}

.scroll2 i {
  text-shadow: 0 0 2px var(--color-white);
}
.scroll3 i {
  text-shadow: 0 0 2px var(--color-white);
}
.scroll:hover i {
  animation-name: rotate;
  animation-duration: 300ms;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
.scroll3:hover i {
  animation-name: rotate;
  animation-duration: 300ms;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes rotate {
  from {
    margin-top: 15px;
  }
  to {
    margin-top: 5px;
  }
}
.visible {
  right: 30px;
  transition: all 400ms;
  transform: rotate(360deg);
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}
@media screen and (min-width: 1440px) {
  .scroll4 svg {
    display: none;
  }
}
@media screen and (max-width: 1440px) {
  .scroll2 {
    top: 70%;
  }
  .scroll4 svg {
    display: none;
  }
}
/*===================MEDIA QUERYIES (SMALL DEVICES)======================*/
@media screen and (max-width: 768px) {
  .scroll4 svg {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .scroll4,
  .scroll3 {
    top: 75%;
  }
  .scroll {
    display: none;
  }
  .scroll4 span {
    display: none;
  }
  .scroll4 svg {
    display: block;
    margin-left: 1.3rem;
  }
}
