.products-form > select {
  margin: 0;
}
.multiselect {
  margin: 0;
  border: 1px solid var(--color-paragraph);
  width: 100%;
}
#productName {
  text-transform: capitalize;
}
#usualWeight {
  text-transform: capitalize;
}
.product-langs,
.product,
.Package,
.product-info {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 15px;
  margin-top: 20px;
  width: 100%;
}
.agent-prod{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 15px;
  margin-top: 20px;
  width: 100%;
}
.filter-category {
  width: fit-content;
}
.product,
.Package {
  align-items: center;
}
.box-barcode {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 15px;
}

.btn {
  display: flex;
  justify-content: start;
}
.groub-of-editor {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 30px;
  justify-content: center;
  align-items: center;
}
.filters {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  gap: 30px;
  justify-content: center;
  align-items: center;
}
.peg {
  display: flex;
  width: 100%;
  margin: 0;
  justify-content: center;
  gap: 10px;
}
.peg .dis {
  cursor: not-allowed;
}
.peg button {
  border: none;
  cursor: pointer;
}
.peg button:hover {
  background: transparent;
}

.wrapper-class {
  padding: 1rem;
}
.editor-class {
  background-color: white;
  padding: 1rem;
}
.toolbar-class {
  border: 1px solid #ccc;
  margin-bottom: 0;
}
.rdw-editor-toolbar {
  margin-bottom: 0;
}
.tabel-drag {
  background-color: white;
  padding: 10px;
  /* width: 1200px;
  overflow-x: scroll; */
  padding: 20px !important;

  /* box-shadow: ; */
}
.tabel-drag thead td {
  width: fit-content;
  padding: 10px;
}
.tabel-drag tr {
  padding: 10px !important;
}
.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px;
}
.card-grid .card-item{
  width: 100%;
  background-color: white;
  border-radius: 1px;
  padding: 10px;
  text-align: center;
}