.cart-container {
  padding: 6% 6%;
  border-radius: 24px;
  width: 100%;
}

.cart-items {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 32px;
  gap: 32px;
}

/* cart summary */
.cart-summary {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.cart-summary .cart-btn {
  width: 40%;
  margin: 50px 0 50px 0;
}
.cart-summary .clear-btn {
  height: 40px;
  border-radius: 5px;
  font-weight: 400;
  letter-spacing: 1.15px;
  border: 0.5px solid rgb(177, 177, 177);
  color: gray;
  background: none;
  outline: none;
  cursor: pointer;
  margin: 0;
}
.cart-checkout {
  width: 270px;
  max-width: 100%;
}
.cart-checkout .subtotal {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
}
.cart-checkout .amount {
  font-weight: 700;
}
.cart-checkout p {
  font-size: 14px;
  font-weight: 200;
  margin: 0.5rem 0;
}
.cart-checkout button {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  font-weight: 400;
  letter-spacing: 1.15px;
  background-color: var(--color-primary);
  border: none;
  outline: none;
  cursor: pointer;
}
.start-shopping button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 32px;
  width: 188px;
  height: 70px;
  background: linear-gradient(90deg, #ffd960 -3.88%, #ffd13f 103.88%);
  border-radius: 12px;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  margin-top: 56px;
}
.continue-shopping {
  margin-top: 1rem;
}
.continue-shopping a {
  color: var(--color-paragraph);
  text-decoration: none;
  display: flex;
  align-items: center;
}
.continue-shopping a span {
  margin-left: 0.5rem;
}
.cart-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
}

.cart-buttons {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;
}
/*===================MEDIA QUERYIES (MEDIUM DEVICES)======================*/

@media screen and (max-width: 1024px) {
}

/*===================MEDIA QUERYIES (SMALL DEVICES)======================*/

@media screen and (max-width: 600px) {
  .cart-empty img {
    width: 215px;
  }
  .cart-items {
    gap: 24px;
  }
  .cart-container {
    padding: 56px 24px;
  }
  .cart-card-h1 .cart-card-details .cart-card-qty {
    padding: 30px 0;
  }
  .continue-shopping {
    height: 56px;
    background: linear-gradient(
      90deg,
      rgba(255, 217, 96, 0.12) -3.88%,
      rgba(255, 209, 63, 0.12) 103.88%
    );
    border: 1px solid #ffd13f;
    border-radius: 12px;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
    width: 90%;
  }
  .continue-shopping span {
    font-family: "Nunito Sans";
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    color: #121212 !important;
  }
  .cart-buttons {
    gap: 24px;
  }
}
