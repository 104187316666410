.swift-payment-card {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  text-align: center;
  box-shadow: -16px 13px 4px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -9px 9px 0px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -16px 13px 4px 0px rgba(0, 0, 0, 0.75);
  width: 80%;
  height: 100%;
  background: var(--color-white);
  border: 1px solid var(--color-border);
  border-radius: 14px;
}
.swift-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.swift-image div {
  width: 90px;
  height: 90px;
  background: black;
  border-radius: 50%;
  position: relative;
  top: -24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.swift-image small {
  font-size: 32px;
  display: block;
  margin-bottom: 24px;
}
.swift-payment-card button {
  width: 250px;
  height: 100%;
  color: var(--color-secondary);
  border-radius: 16px;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 27px auto;
}
.swift-payment-card button:hover {
  background: var(--color-secondary);
  color: var(--color-white);
  transition: var(--transition);
}
.swift-payment-card button img {
  margin: 10px;
}

#account-number {
  font-size: 24px;
}
/*===================MEDIA QUERYIES (MEDIUM DEVICES)======================*/

@media screen and (max-width: 1024px) {
  .swift-payment-card h2 {
    font-size: 24px;
  }
}

/*===================MEDIA QUERYIES (SMALL DEVICES)======================*/

@media screen and (max-width: 600px) {
  .swift-payment-card {
    width: 90%;
  }
  .swift-payment-card h2 {
    font-size: 14px;
  }

  .swift-image small {
    margin-bottom: 24px;
    font-size: 18px;
  }
  #account-number {
    font-size: 16px;
  }
  .swift-payment-card button {
    width: 15rem !important;
    font-family: "Nunito Sans";
  }
  .swift-image div {
    width: 72px;
    height: 72px;
    top: -24px;
  }
  #finalInvoiceWalletImgContainer {
    display: none;
  }
  .payment-info-step {
    padding-bottom: 24px;
  }
}
