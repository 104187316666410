.jwTXsE {
  display: flex;
  justify-content: center !important;
}
.cpVdBK {
  justify-content: center !important;
  width:10rem !important
}
.icon {
  color:#ffc107 ;
  font-size: 18px;
  cursor: pointer;
}
.form-agent {
  display: flex;
  margin-bottom: 10px;
  height: fit-content;
}
.form-agent button{
  margin:  10px;
}