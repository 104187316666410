.shipment {
  height: 100%;
  width: 100%;
}
.shipment-agent-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10% 5%;
}
.payment-info-step {
  width: 90%;
  height: 100%;
  background: var(--color-white);
  border: 3px dashed var(--color-primary-variant);
  box-shadow: -4px -4px 8px rgba(18, 18, 18, 0.04);
  border-radius: 12px;
  margin: 20px auto;
  padding: 5%;
}
.payment-info-step .row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
#final-span {
  font-weight: bold;
}
.finalInvoiceDataTable {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  font-family: "Nunito Sans";
}
.finalInvoiceDataTable tr {
  display: flex;
  flex-direction: column;
}
.finalInvoiceDataTable tr {
  gap: 22px !important;
}
/*===================MEDIA QUERYIES (SMALL DEVICES)======================*/

@media screen and (max-width: 600px) {
  .payment-info-step {
    padding: 1%;
  }
  .finalInvoiceDataTable {
    display: block;
  }
}
