.create-agent {
  padding-bottom: 5%;
}
.form{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap:20px
}
.form button{
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}
.visitour{
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  width: fit-content;
}