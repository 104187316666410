.admins {
  width: 100%;
}
.create-admin {
  padding: 2%;
}

.checkboxes {
  display: flex;
  flex-direction: column;
}
.roles {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;
}
.roles div {
  margin: 20px;
}
