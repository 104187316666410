@use "../../scss/abstracts/" as *;

.topnav {
  @include flex(center, space-between);
  margin-bottom: $spacing;
  background-color: #ffc107;
  color: black;
  height: 5rem;
  border-radius: 10px;
  padding: 10px;
  @include tablet {
    margin-bottom: $mobileSpacing;
  }
}

.sidebar-toggle {
  --size: 40px;
  width: var(--size);
  height: var(--size);
  @include flex(center, center);
  cursor: pointer;
  display: none;

  @include tablet {
    display: flex;
  }

  i {
    font-size: 3rem;
    color: black;
  }
}

.topnav-info {
  padding: 2%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.topnav-info div:nth-of-type(1) {
  padding: 10px;
  cursor: pointer;
}
.topnav-info div:nth-of-type(1):hover .admin-notifications {
  display: block;
  position: absolute;
  cursor: pointer;
}
.topnav-info div:nth-of-type(1):hover .admin-notifications:hover {
  display: block;
  position: absolute;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
